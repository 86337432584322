<template>
  <div :style="$vuetify.display.width < 600 ? { padding: '64px 0 80px 0' } : { padding: '85px 30px 30px 30px' }">


    <section class="d-flex justify-space-between">
      <div class="d-flex flex-column" style="line-height: 1.2;">
        <span style="color:#0077ff;">Ventas</span>
        <div style="color:#000;font-size: 26px;">Sucursal: <strong>Hacienda Echeveste</strong></div>
      </div>
      <button type="button" :class="$vuetify.display.width < 600 ? 'btn_movil_agregar_venta':'btn_agregar_venta'" @click="add_pedido = true"><i class="fa-solid fa-plus" :class="$vuetify.display.width < 600 ? '':'mr-3'"></i>{{ $vuetify.display.width < 600 ? '':'Agregar Pedido' }}</button>
    </section>

  <div style="overflow: auto;">
    <div class="d-flex gap-2 pt-4" style="padding-bottom: 20px;">

      <!-- <v-card class="pa-4" style="max-width: 35%;">
        <v-list style="background: transparent;" class="d-flex flex-column">

          <div v-for="sucursal in sucursales_empresa" :key="sucursal" class="mb-6">

            <div class="mb-2 pa-2" style="background: #efefef">
              {{ sucursal.nombre_sucursal }}
            </div>

            <template v-for="cliente in datos_cliente" :key="cliente">

              <v-list-item :value="cliente" class="pa-2"
                v-if="sucursal.nombre_sucursal == cliente.sucursal_compra_cliente" @click="pedido_cliente()">

                <div class="d-flex gap-4 align-center">
                  <img style="width: 100%;border-radius: 5px;width:50px;height: 50px;"
                    src="https://social.webestica.com/assets/images/avatar/07.jpg" />

                  <div class="d-flex flex-column" style="">
                    <span style="font-weight: 600;color:#0077ff">{{ cliente.nombre_cliente }}</span>
                    <span style="font-weight: 500;">{{ cliente.sucursal_compra_cliente }}</span>
                  </div>
                </div>

              </v-list-item>

            </template>
          </div>

        </v-list>
      </v-card> -->

      <div v-for="venta in grupo_ventas" :key="venta">
        <div class="card flex-grow-1 h-100">
          <div class="image"></div>
            <div class="content">
              <span class="title_pedido_venta">
                {{ venta.id_cliente }}
              </span>
  
              <p class="desc">
                {{ venta.nota_venta }}
              </p>
  
              <a class="action" href="#">
                Find out more
                <span aria-hidden="true">
                  →
                </span>
              </a>
            </div>
          </div>
      </div>
    </div>


    <div>

      </div>

    </div>



  </div>


  <v-dialog v-model="add_pedido" fullscreen :scrim="false" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark style="background: #000;margin-top: 90px;" class="d-flex justify-space-between gap-2">

        <v-btn icon variant="plain" style="color: #fff" @click="add_pedido = false">
          <i class="fa-solid fa-angle-left" style="color:#fff"></i>
        </v-btn>

        <v-toolbar-title style="color: #fff;padding-right: 20px;"
          :style="$vuetify.display.width < 752 ? {textAlign: 'right'}:{textAlign: 'center'}">Nuevo
          Pedido</v-toolbar-title>

      </v-toolbar>
    
      <div style="height: 100%;overflow: auto;padding-bottom: 140px;padding-top: 15px;padding-left: 15px;padding-right: 15px">
        <!-- <form @submit.prevent="submitForm" class="d-flex flex-wrap gap-2">
              <div class="form-group flex-grow-1">
                  <label for="id_cliente">ID Cliente:</label>
                  <input type="text" class="form-control" id="id_cliente" v-model="formData.id_cliente">
              </div>
              <div class="form-group flex-grow-1">
                  <label for="id_articulo">ID Artículo:</label>
                  <input type="text" class="form-control" id="id_articulo" v-model="formData.id_articulo">
              </div>
              <div class="form-group flex-grow-1">
                  <label for="precio_articulo">Precio Artículo:</label>
                  <input type="text" class="form-control" id="precio_articulo" v-model="formData.precio_articulo">
              </div>
              <div class="form-group flex-grow-1">
                  <label for="fecha_apartado">Fecha Apartado:</label>
                  <input type="date" class="form-control" id="fecha_apartado" v-model="formData.fecha_apartado">
              </div>
              <div class="form-group flex-grow-1">
                  <label for="pago_cliente">Pago Cliente:</label>
                  <input type="text" class="form-control" id="pago_cliente" v-model="formData.pago_cliente">
              </div>
              <div class="form-group flex-grow-1">
                  <label for="fecha_ultimo_pago">Fecha Último Pago:</label>
                  <input type="date" class="form-control" id="fecha_ultimo_pago" v-model="formData.fecha_ultimo_pago">
              </div>
              <div class="form-group flex-grow-1">
                  <label for="status_apartado">Status Apartado:</label>
                  <input type="text" class="form-control" id="status_apartado" v-model="formData.status_apartado">
              </div>
              <div class="w-100 d-flex justify-end" :style="$vuetify.display.width < 600 ? {position: 'fixed',bottom: '40px',paddingRight: '25px'}:{}">
                <div class="button">
                    <div class="button_lg">
                        <span class="button_sl"></span>
                        <button class="button_text">Finalizar Venta</button>
                    </div>
                </div>
              </div>
          </form> -->
        
          <div class="master-container">
  <div class="card cart">
    <label class="title">Your cart</label>
    <div class="products">
      <div class="product">
        <svg fill="none" viewBox="0 0 60 60" height="60" width="60" xmlns="http://www.w3.org/2000/svg">
<rect fill="#FFF6EE" rx="8.25" height="60" width="60"></rect>
<path stroke-linejoin="round" stroke-linecap="round" stroke-width="2.25" stroke="#FF8413" fill="#FFB672" d="M34.2812 18H25.7189C21.9755 18 18.7931 20.5252 17.6294 24.0434C17.2463 25.2017 17.0547 25.7808 17.536 26.3904C18.0172 27 18.8007 27 20.3675 27H39.6325C41.1993 27 41.9827 27 42.4639 26.3904C42.9453 25.7808 42.7538 25.2017 42.3707 24.0434C41.207 20.5252 38.0246 18 34.2812 18Z"></path>
<path fill="#FFB672" d="M18 36H17.25C16.0074 36 15 34.9926 15 33.75C15 32.5074 16.0074 31.5 17.25 31.5H29.0916C29.6839 31.5 30.263 31.6754 30.7557 32.0039L33.668 33.9453C34.1718 34.2812 34.8282 34.2812 35.332 33.9453L38.2443 32.0039C38.7371 31.6754 39.3161 31.5 39.9084 31.5H42.75C43.9926 31.5 45 32.5074 45 33.75C45 34.9926 43.9926 36 42.75 36H42M18 36L18.6479 38.5914C19.1487 40.5947 20.9486 42 23.0135 42H36.9865C39.0514 42 40.8513 40.5947 41.3521 38.5914L42 36M18 36H28.5ZM42 36H39.75Z"></path>
<path stroke-linejoin="round" stroke-linecap="round" stroke-width="2.25" stroke="#FF8413" d="M18 36H17.25C16.0074 36 15 34.9926 15 33.75C15 32.5074 16.0074 31.5 17.25 31.5H29.0916C29.6839 31.5 30.263 31.6754 30.7557 32.0039L33.668 33.9453C34.1718 34.2812 34.8282 34.2812 35.332 33.9453L38.2443 32.0039C38.7371 31.6754 39.3161 31.5 39.9084 31.5H42.75C43.9926 31.5 45 32.5074 45 33.75C45 34.9926 43.9926 36 42.75 36H42M18 36L18.6479 38.5914C19.1487 40.5947 20.9486 42 23.0135 42H36.9865C39.0514 42 40.8513 40.5947 41.3521 38.5914L42 36M18 36H28.5M42 36H39.75"></path>
<path stroke-linejoin="round" stroke-linecap="round" stroke-width="3" stroke="#FF8413" d="M34.512 22.5H34.4982"></path>
<path stroke-linejoin="round" stroke-linecap="round" stroke-width="2.25" stroke="#FF8413" d="M27.75 21.75L26.25 23.25"></path>
</svg>
        <div>
          <span>Cheese Burger</span>
          <p>Extra Spicy</p>
          <p>No mayo</p>
        </div>
        <div class="quantity">
          <button>
            <svg fill="none" viewBox="0 0 24 24" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#47484b" d="M20 12L4 12"></path>
            </svg>
          </button>
          <label>2</label>
          <button>
            <svg fill="none" viewBox="0 0 24 24" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#47484b" d="M12 4V20M20 12H4"></path>
            </svg>
          </button>
        </div>
        <label class="price small">$23.99</label>
      </div>
    </div>
  </div>

  <div class="card coupons">
    <label class="title">Apply coupons</label>
    <form class="form">
        <input type="text" placeholder="Apply your coupons here" class="input_field">
        <button>Apply</button>
    </form>
  </div>

  <div class="card checkout">
    <label class="title">Checkout</label>
    <div class="details">
      <span>Your cart subtotal:</span>
      <span>47.99$</span>
      <span>Discount through applied coupons:</span>
      <span>3.99$</span>
      <span>Shipping fees:</span>
      <span>4.99$</span>
    </div>
    <div class="checkout--footer">
      <!-- <button class="checkout-btn">Checkout</button> -->

      <div class="w-100 d-flex justify-space-between align-center gap-4" :style="$vuetify.display.width < 600 ? {position: 'fixed',left: '0',bottom: '60px',padding: '0 15px'}:{}">
        
      <label class="price"><sup>$</sup>57.99</label>
        
        <div class="button" :style="$vuetify.display.width < 600 ? {width: '100%'}:{}">
            <div class="button_lg">
                <span class="button_sl"></span>
                <button class="button_text">Finalizar Venta</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
        
        </div>

    </v-card>
  </v-dialog>

</template>

<script>
import axios from 'axios';


export default ({
  data: vm => ({
    numberFormat: new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 0
    }),
    grupo_ventas: [],
    add_pedido: false,

    datos_cliente: [
      {
        id_cliente: 0,
        id_pedido_cliente: 2,
        nombre_cliente: 'Denisse Mendez',
        sucursal_compra_cliente: 'Plaza Villas Miércoles',
        imagen_cliente: '',
        saldo_cliente: 420,
        saldo_pendiente_cliente: 320,
        abonos_cliente: [{ fecha_abono: '11/10/2024', cantidad_abono: 100 }],
        fecha_venta: '07/10/2024',
        tipo_venta: 'Apartado'
      },
      {
        id_cliente: 0,
        id_pedido_cliente: 2,
        nombre_cliente: 'Lizbet Medina',
        sucursal_compra_cliente: 'Plaza Villas Viernes',
        imagen_cliente: '',
        saldo_cliente: 420,
        saldo_pendiente_cliente: 320,
        abonos_cliente: [{ fecha_abono: '11/10/2024', cantidad_abono: 100 }],
        fecha_venta: '07/10/2024',
        tipo_venta: 'Apartado'
      },
      {
        id_cliente: 0,
        id_pedido_cliente: 2,
        nombre_cliente: 'Denisse Mendez',
        sucursal_compra_cliente: 'Plaza Villas Miércoles',
        imagen_cliente: '',
        saldo_cliente: 420,
        saldo_pendiente_cliente: 320,
        abonos_cliente: [{ fecha_abono: '11/10/2024', cantidad_abono: 100 }],
        fecha_venta: '07/10/2024',
        tipo_venta: 'Apartado'
      },
      {
        id_cliente: 0,
        id_pedido_cliente: 2,
        nombre_cliente: 'Lizbet Medina',
        sucursal_compra_cliente: 'Plaza Villas Viernes',
        imagen_cliente: '',
        saldo_cliente: 420,
        saldo_pendiente_cliente: 320,
        abonos_cliente: [{ fecha_abono: '11/10/2024', cantidad_abono: 100 }],
        fecha_venta: '07/10/2024',
        tipo_venta: 'Apartado'
      }
    ],
    sucursales_empresa: [
      {
        nombre_sucursal: 'Plaza Villas Miércoles'
      },
      {
        nombre_sucursal: 'Plaza Villas Viernes'
      }
    ],


    loading: false,
      rules: [value => vm.checkApi(value)],
      timeout: null,
      userName: '',

      formData: {
          id_cliente: '',
          id_articulo: '',
          precio_articulo: '',
          fecha_apartado: '',
          pago_cliente: '',
          fecha_ultimo_pago: '',
          status_apartado: ''
      }
  }),
  created(){
    this.mostrar_productos()
  },
  methods: {
     
  mostrar_productos(){
    axios.get('https://nexus-ux.com/microservicio/public/api/ventas/consultarVentas')
      .then(res => {
        let datos = [1,2]
        
        datos.forEach( () => {
        res.data.forEach(item =>{

          this.grupo_ventas.push(item)
        })
      });
    })
    .catch(error => {
      console.error("Error uploading the image:", error);
    });
    console.log('Venta: ', this.grupo_ventas);
  },

     pedido_cliente(){
      
     },

     async submit (event) {
        this.loading = true

        const results = await event

        this.loading = false

        alert(JSON.stringify(results, null, 2))
      },
      async checkApi (userName) {
        return new Promise(resolve => {
          clearTimeout(this.timeout)

          this.timeout = setTimeout(() => {
            if (!userName) return resolve('Please enter a user name.')
            if (userName === 'johnleider') return resolve('User name already taken. Please try another one.')

            return resolve(true)
          }, 1000)
        })
      },
      
      submitForm() {
                // Aquí puedes enviar los datos del formulario a tu backend para guardarlos en la base de datos
                console.log(this.formData);
                // Por ahora, solo mostramos los datos en la consola
                alert('Registro agregado exitosamente!');
            }
}
})
</script>

<style>
.btn_movil_agregar_venta{
  background: #000;
  color: #fff !important;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 65px;
  right: 15px;
}

.btn_agregar_venta {
  background-color: #000000;
  color: #fff !important;
  padding: 8px 80px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.btn_agregar_venta:hover {
  background-color: #131313;
}

.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #0f1923;
  cursor: pointer;
  position: relative;
  padding: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all .15s ease;
  text-align: center;
}

.button::before,
.button::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid #7D8082;
  transition: all .15s ease;
}

.button::before {
  top: 0;
  border-bottom-width: 0;
}

.button::after {
  bottom: 0;
  border-top-width: 0;
}

.button:active,
.button:focus {
  outline: none;
}

.button:active::before,
.button:active::after {
  right: 3px;
  left: 3px;
}

.button:active::before {
  top: 3px;
}

.button:active::after {
  bottom: 3px;
}

.button_lg {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #fff;
  background-color: #0f1923;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.button_lg::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #0f1923;
}

.button_lg::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: #0f1923;
  transition: all .2s ease;
}

.button_sl {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -8px;
  width: 0;
  background-color: #00c020;
  transform: skew(-15deg);
  transition: all .2s ease;
}

.button_text {
  position: relative;
}

.button:hover {
  color: #0f1923;
}

.button:hover .button_sl {
  width: calc(100% + 15px);
}

.button:hover .button_lg::after {
  background-color: #fff;
}



.master-container {
  display: grid;
  grid-template-columns: auto;
  gap: 5px;
}

.card {
  border-color: #d1dcf5 !important;
  width: 220px;
  background: #FFFFFF;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.title {
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #efeff3;
  font-weight: 700;
  font-size: 11px;
  color: #63656b;
}

/* cart */
.cart {
  border-radius: 19px 19px 7px 7px;
}

.cart .products {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.cart .products .product {
  display: grid;
  grid-template-columns: 60px 1fr 80px 1fr;
  gap: 10px;
}

.cart .products .product span {
  font-size: 13px;
  font-weight: 600;
  color: #47484b;
  margin-bottom: 8px;
  display: block;
}

.cart .products .product p {
  font-size: 11px;
  font-weight: 600;
  color: #7a7c81;
}

.cart .quantity {
  height: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
}

.cart .quantity label {
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
  color: #47484b;
}

.cart .quantity button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: none;
  background-color: transparent;
  padding-bottom: 2px;
}

.card .small {
  font-size: 15px;
  margin: 0 0 auto auto;
}

.card .small sup {
  font-size: px;
}

/* coupons */
.coupons {
  border-radius: 7px;
}

.coupons form {
  display: grid;
  grid-template-columns: 1fr 80px;
  gap: 10px;
  padding: 10px;
}

.input_field {
  width: auto;
  height: 36px;
  padding: 0 0 0 12px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}

.coupons form button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 10px;
  width: 100%;
  height: 36px;
  background: linear-gradient(180deg, #4480FF 0%, #115DFC 50%, #0550ED 100%);
  box-shadow: 0px 0.5px 0.5px #EFEFEF, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 5px;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

/* Checkout */
.checkout {
  border-radius: 9px 9px 19px 19px;
}

.checkout .details {
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 10px;
  gap: 5px;
}

.checkout .details span {
  font-size: 13px;
  font-weight: 600;
}

.checkout .details span:nth-child(odd) {
  font-size: 11px;
  font-weight: 700;
  color: #707175;
  margin: auto auto auto 0;
}

.checkout .details span:nth-child(even) {
  font-size: 13px;
  font-weight: 600;
  color: #47484b;
  margin: auto 0 auto auto;
}

.checkout .checkout--footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  background-color: #efeff3;
}

.price {
  position: relative;
  font-size: 22px;
  color: #2B2B2F;
  font-weight: 900;
}

.price sup {
  font-size: 13px;
}

.price sub {
  width: fit-content;
  position: absolute;
  font-size: 11px;
  color: #5F5D6B;
  bottom: 5px;
  display: inline-block;
}

.checkout .checkout-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 36px;
  background: linear-gradient(180deg, #4480FF 0%, #115DFC 50%, #0550ED 100%);
  box-shadow: 0px 0.5px 0.5px #EFEFEF, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 7px;
  border: 0;
  outline: none;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}




.card {
  max-width: 300px;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
}

.card a {
  text-decoration: none
}

.content {
  padding: 1.1rem;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 300px;
  background-color: #eef3fe;
}

.title_pedido_venta {
  color: #111827;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.desc {
  margin-top: 0.5rem;
  color: #6B7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.action {
  display: inline-flex;
  margin-top: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  align-items: center;
  gap: 0.25rem;
  background-color: #2563EB;
  padding: 4px 8px;
  border-radius: 4px;
}

.action span {
  transition: .3s ease;
}

.action:hover span {
  transform: translateX(4px);
}

</style>