import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap'


loadFonts()

createApp(App)
  .use(router)
  .use(vuetify)
  .mount('#app')

  

// Obtén la ruta base de la aplicación
const base = import.meta.url;

// Construye la ruta al Service Worker
const swPath = `${base}sw.js`.replace('main.js', '');

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register(swPath)
    .then(() => {
      // console.log("Service Worker registrado con éxito:", registration);
    })
    .catch((error) => {
      console.error("Error al registrar el Service Worker:", error);
    });
}

  