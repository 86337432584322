import { reactive, createApp } from 'vue';
import axios from 'axios';

const app = createApp({});

export const state = reactive({
  menu_items: [
      { title: 'Inicio', sub_menu:  [], link: '/', icon: 'fa-solid fa-house' },
      { title: 'Dashboard', sub_menu:  [], link: '/dashboard', icon: 'fa-solid fa-store'},
      { title: 'Pedidos', sub_menu:  [], link: '/pedidos', icon: 'fa-solid fa-store'},
      // { title: 'Home', sub_menu:  [], link: '/', icon: './assets/img/icon_liz.svg', rules: () => this.$vuetify.display.xs},
      { title: 'Clientes', sub_menu:  [], link: '/clientes', icon: 'fa-solid fa-user-tag'},
      { title: 'Ventas', sub_menu:  [], link: '/ventas', icon: 'fa-solid fa-user-tag'},
      // { title: 'Ventas', sub_menu:  [ { title: 'Ventas', sub_menu:  [], link: '/ventas', icon: 'fa-solid fa-tags'},{ title: 'Apartados', sub_menu:  [], link: '/ventas', icon: 'fa-solid fa-tags'}], link: '', icon: ''}
    ],
    images_populares: [],
    productos: [],
    loading: false,
    page: 1,
    limit: 30,
    datos_sucursales: [],
    buscador_productos: '',
    images_populares_filter: [],
    sessionActive: null
  })

  
  export const mostrar_productos = () => {
    axios.get('https://nexus-ux.com/microservicio/public/api/producto/consultarProductos')
      .then(res => {
        if (res.data && Array.isArray(res.data)) {
          // state.images_populares = res.data
          
          // Limitar la cantidad de productos
          const limitedProducts = res.data; // Limitar a 10 productos, ajusta según necesites
          
          // Asignar los productos limitados a state.images_populares
          state.productos = limitedProducts.slice(0, 10);
        } else {
          console.error('Datos no válidos recibidos de la API');
        }
      })
      .catch(error => {
        console.error("Error al consultar los articulos:", error);
      });
  };

  export const mostrar_producto_unico = (data_productos) => {
    console.log('data_productos: ', data_productos);
  axios.get(`https://nexus-ux.com/microservicio/public/api/producto/multiple/${data_productos}`)
   .then(res => {
     let articulo = res.data.original[0]
     let articulo_encontrado = state.productos.findIndex(item => item.id == articulo.id)
     state.productos[articulo_encontrado] = articulo
     console.log('Data: ', res.data.original[0].id, articulo_encontrado);
    })
   .catch(error => {
      console.error("Error al consultar el articulo:", error);
    });
};

  export const mostrar_sucursales = () => {
    axios.get('https://nexus-ux.com/microservicio/public/api/sucursales/consultarSucursales')
      .then(res => {
        if (res.data && Array.isArray(res.data)) {
          state.datos_sucursales = res.data
          console.log('datos_sucursales: ', state.datos_sucursales);
        } else {
          console.error('Datos no válidos recibidos de la API');
        }
      })
      .catch(error => {
        console.error("Error uploading the image:", error);
      });
  };
  
  // export const logeo = (usuario, password) => {
    
  //   if(usuario == 'Lizbeth' && password == 'Liz1234'){
  //     usuario = ''
  //     password = '' 
  //     localStorage.setItem("sessionActive", 1)
  //     recuperarLogin()
  //   }
  // };
  // export const logout = () => {
  //   localStorage.clear();
  //   recuperarLogin()

  //   };

  export const recuperarLogin = () => {
    state.sessionActive = localStorage.getItem("sessionActive")
    console.log('sessionActive: ', state.sessionActive);
  };
// export const mostrar_datos = async () => {

//   const options = {
//     method: 'GET',
//     url: 'https://shein-xi-yin-data-service.p.rapidapi.com/store/get_store_items',
//     params: {
//       store_code: '26465598',
//       country: 'US',
//       language: 'en',
//       currency: 'USD',
//       page: '1',
//       size: '20',
//       category: 'women'
//     },
//     headers: {
//       'X-RapidAPI-Key': '62b417eef7msh9a1a3b9562aa81fp1e5b1bjsna82d7a533ab6',
//       'X-RapidAPI-Host': 'shein-Xi-Yin-data-service.p.rapidapi.com'
//     }
//   };

//   try {
//     const response = await axios.request(options);
//     console.log(response.data);
//   } catch (error) {
//     console.error(error);
//   }


// }



// export const guardar_articulo = async (image_upload) => {

//   let data_img = null;
//   if (image_upload != null) {
//     let nombreImg = image_upload[0].name;
//     if (nombreImg.includes(".jpg") || nombreImg.includes(".png")) {
//       if (image_upload[0].size > 2000000) {
//         this.$swal("¡Oops, la imagen supera los 2 MB!", "Sube una más ligera", "info");
//       }
//       data_img = new FormData();
//       data_img.append("img", image_upload[0]);
//     } else {
//       this.$swal("¡Oops, el archivo no es una imagen!", "Sube una imagen", "info");
//     }
//   }

//   if (data_img) {
//     axios.post('https://nexus-ux.com/microservicio/public/api/img/subirImg', data_img).then(res => {
//       // widget.value = res.data.path.replace(/\\/g, '/');
//       console.log('respuesta: ', res.data.path);
//     }).catch(error => {
//       console.error("Error uploading the image:", error);
//     });
//   }
// }



// // AQUÍ DECLARA LAS FUNCIONES
app.config.globalProperties.mostrar_productos = mostrar_productos