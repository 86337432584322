<template>
  <div style="position: fixed;width:100%;z-index: 996;">
    <!-- <div style="width:100%;background: rgb(17, 17, 19);height: 30px;font-size: 12px;"
      class="d-flex align-center justify-center">

      <div class="d-flex align-center justify-end" style="width: 1150px">
        <router-link v-for="item of state.menu_items" :key="item" :to="item.link" class="btn_nav"
          :active-class="[!activeButton ? 'btn_nav_active':'']">
          <span style="color: #fff !important;">{{ item.title }}</span>
        </router-link>
      </div>

    </div> -->
    <div class="nav_bar" :style="{ background: navBackgroundColor }">

      <div class="d-flex justify-space-between align-center gap-5" style="width: 1150px;"
        :style="$vuetify.display.width < 600 ? { padding: '0 20px' } : { padding: '0 30px' }">

        <!-- <v-btn icon="$vuetify" density="compact" variant="text" style="width:35px;height: 35px;"><i class="fa-solid fa-magnifying-glass"></i></v-btn> -->

        <img :src="require('../assets/img/logo-lizbeth.svg')" style="width:120px;height: 100%;" />

        <!-- <div class="box_search">
          <v-text-field :loading="loading" density="compact" variant="plain" label="Buscar" prepend-icon="mdi-magnify" v-model="state.buscador_productos" single-line hide-details @click:append-inner="onClick" @input="buscar_productos()"></v-text-field>
        </div> -->
        <!-- <a v-for="route of menu" :key="route" :href="route.link" id="btn_nav">
            {{ route.title }}
          </a> -->

        <div class="d-flex gap-2">
          <!-- <v-btn icon="$vuetify" density="compact" variant="text" style="width:35px;height: 35px;" @click="recuperarLogin()">
            <i class="fa-solid fa-bell" style="color:#0084ff"></i>
          </v-btn> -->

          <notifications_section />


          <!-- <v-btn icon="$vuetify" density="compact" variant="text" style="width:35px;height: 35px;" @click="logeo()">
            <img class="box_img_enterprise gap-2" :src="require('../assets/img/icon_app_liz.svg')" />
          </v-btn> -->


          <v-menu rounded transition="slide-y-transition" v-model="menu_login" :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" icon="$vuetify" density="compact" variant="text" style="width:35px;height: 35px;">
                <img class="box_img_enterprise gap-2" :src="require('../assets/img/icon_app_liz.svg')" />
              </v-btn>
            </template>
            <v-card class="mt-2 ml-n7" style="background: #000000d9;color:#fff;backdrop-filter: blur(3px);z-index: 5;width: 280px">
                <v-col class="pa-4">
                  <v-col cols="12" class="d-flex align-center"
                    v-if="state.sessionActive == 1 || state.sessionActive == 2">
                    <img class="box_img_enterprise gap-2" :src="require('../assets/img/icon_app_liz.svg')" />
                    <v-col class="text-left">
                      <h3>{{ state.sessionActive == 1 ? 'Lizbeth Mendez' : state.sessionActive == 2 ? 'Angélica Marisol':''}}</h3>
                      <p class="text-caption mt-1">
                        {{ state.sessionActive == 1 ? '477 268 9595' : state.sessionActive == 2 ? '477 795 6017' : '' }}
                      </p>
                    </v-col>

                  </v-col>

                  <div v-if="state.sessionActive == null">
                    <v-form fast-fail @submit.prevent>
                      <v-text-field variant="outlined" v-model="usuario" label="Usuario"></v-text-field>

                      <!-- <v-text-field type="password" v-model="lastName" :rules="lastNameRules" label="Contraseña"></v-text-field> -->
                      <v-text-field variant="outlined" v-model="password" label="Contraseña"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"></v-text-field>

                      <v-btn variant="outlined" class="w-100" style="height: 50px" @click="logeo(usuario, password)">Login</v-btn>

                    </v-form>
                  </div>

                  <v-divider class="my-3" v-if="state.sessionActive == 1 || state.sessionActive == 2"></v-divider>

                  <v-btn v-if="state.sessionActive == 1 || state.sessionActive == 2" variant="text" class="w-100 d-flex justify-start align-center" style="height: 50px;" @click="logout(usuario, password)">
                    Cerrar Sesión
                  </v-btn>

                </v-col>
                <v-divider></v-divider>
                <v-btn color="primary" variant="text" class="d-flex justify-centers align-center" style="width: 100%;height: 50px;" @click="installar_app()">
                
                  <span class="material-icons mr-2" style="color: #008cff">
                    gpp_good
                  </span>
                  <span style="color:#dfedf8">
                    Instalar APP
                  </span>

                </v-btn>
            </v-card>
          </v-menu>

        </div>

      </div>
    </div>
  </div>

  <div style="position: fixed;bottom:20px;left:20px;z-index: 998;" class="d-flex align-center gap-2"
    v-if="state.sessionActive == 1">

    <v-btn id="btn_open_menu"
      style="background: #fff;border-radius:50px;height: 55px !important;min-width: 40px !important;max-width: 40px !important;"
      :style="nav_menu_movil ? {} : { boxShadow: '0 5px 5px rgba(100,150,200,0.2)' }" variant="text"
      @click="nav_menu_movil = !nav_menu_movil">
      <img :src="require('../assets/img/icon_liz.svg')" style="width:30px;" />
    </v-btn>
    <span style="width: 100px;padding:4px 5px;font-size: 14px;line-height: 1.1;color: #fff;border-radius: 3px;"
      :style="nav_menu_movil ? { background: 'rgba(0, 0, 0, 0.4)' } : { background: '#000' }">
      <div style="font-size: 12px;"><span style="color:yellow">Menu</span> administrador</div>
    </span>
  </div>




  <div class="d-flex flex-column align-center h-100" id="menu_sidebar"
    style="overflow:hidden;min-width: 0px;width:0px;max-width: 100%;resize:horizontal;transition: all 100ms ease-out;background: #fff;box-shadow: 0px 5px 8px #6496c833;position: fixed;z-index: 997;padding: 0;"
    :style="nav_menu_movil ? { minWidth: '320px', display: 'flex', transition: 'all 100ms', background: '#000000d9', color: '#fff', backdropFilter: 'blur(6px)', width: $vuetify.display.width < 600 ? '100%' : '100%' } : { minWidth: '0px', width: '0px', background: '#00000099', transition: 'all 100ms' }">


    <div style="display:none;position:relative;overflow: hidden;" v-if="nav_menu_movil"
      :style="nav_menu_movil ? { display: 'flex' } : { display: 'none' }" class="flex-column w-100 py-8 h-100">


      <!-- <v-tabs id="menu_tabs" class="menu_tabs_box" style="margin-top: 50px" :next-icon="false" :prev-icon="false">
  <div v-for="(menu, i) of menuItems" :key="menu">

    <v-tab v-if="menu.rules ?  menu.rules(menu, i) : true" style="min-width: 60px !important;">
      <i :class="menu.icon" v-if="menu.icon"></i>
      <span :value="menu.title">{{menu.title}}</span>
    </v-tab>


  </div>
</v-tabs> -->

      <!-- <v-tabs id="menu_tabs" animationDuration="100ms" (selectedTabChange)="onFirstTabSelectionChange($event)" [selectedIndex]="selectedSecondTabIndex" :style="selectedSecondTabIndex != 0 ? {height: 'auto'}:{}"> -->
      <template v-for="(menu, i) of menuItems" :key="menu">
        <div v-if="menu.rules ? menu.rules(menu, i) : true" class="h-100">

          <div v-for="item_menu of menu.items" :key="item_menu" class="h-100">
            <!-- <div class="d-flex gap-2 align-items-center justify-content-between flex-column flex-wrap" v-if="item_menu.rules ?  item_menu.rules(item_menu, item_menu_index) : true"> -->
            <div class="d-flex gap-2 align-items-center justify-content-between flex-column flex-wrap h-100">


              <div class="d-flex flex-column align-center justify-center"
                :style="item_menu.title != 'Favoritos' ? { height: '100%' } : {}">

                <div class="d-flex w-100 align-items-center justify-content-between"
                  style="border-bottom: 1px solid #ffffff10;padding:10px 0;" v-if="selectedSecondTabIndex === 0">

                  <span class="w-100">Hola <span style="color:#fdd704;">{{ 'Nombre Usuario' }}</span></span>
                  <a class="btn_logout" aria-haspopup="true">
                    <v-icon>power_settings_new</v-icon>
                  </a>

                </div>

                <!-- <div class="w-100 d-flex justify-content-between align-items-center" style="padding: 13px 15px;">
            <h5 :style="{color: item_menu.color}" class="box_menu_title d-flex align-items-center"><i :class="item_menu.icon" class="mr-2" v-if="item_menu.title != 'Favoritos'"></i>
              <v-icon v-if="item_menu.title === 'Favoritos'" style="font-size: 15px;" class="mr-2 d-flex align-items-center justify-content-center">star</v-icon>
              {{item_menu.title}}
            </h5>

            <i v-if="item_menu.title === 'Favoritos' && screen.isXSmall || screen.isSmall" style="font-size: 15px;" :style="edit_menu_favoritos ? {color: '#007BFF'}:{color: '#d5e9f64d'}" class="fa-regular fa-pen-to-square puntero" @click="edit_menu_favoritos = !edit_menu_favoritos"></i>
          </div> -->

                <!-- <div class="box_menu" > -->

                <!-- <template v-for="item of item_menu.items" :key="item"> -->

                <div class="box_menu">
                  <div class="d-flex flex-wrap w-100 gap-2">

                    <router-link v-for="item of state.menu_items" @click="nav_menu_movil = false" :key="item"
                      :to="item.link" class="btn_nav" :active-class="[!activeButton ? 'btn_nav_active' : '']">
                      <div style="color: #fff !important;">{{ item.title }}</div>
                    </router-link>
                  </div>
                </div>

                <!-- <div class="d-flex w-100 align-items-center justify-content-between" v-if="item.rules ? item.rules(item, item_index):true">
                <button v-button @click="item.href ? item.href():'';nav_menu_movil = false" style="color: transparent;">
                  {{item.name}}
                </button>
                <v-icon v-if="item.rules ? item.rules(item, item_index) &&  item_menu.title != 'Favoritos':item_menu.title != 'Favoritos' ? true:false" class="puntero d-flex align-items-center justify-content-center icon_star">star</v-icon>
                <button class="btn_delete_favoritos" v-if="item_menu.title === 'Favoritos'">
                  <v-icon class="puntero d-flex align-items-center justify-content-center icon_delete_favoritos" style="font-size: 15px;color:#eb4949">close</v-icon>
                </button>

              </div> -->

                <!-- </template> -->
                <!-- <span style="font-size: 12px;text-align: center;color: #ffffff90;max-width: 270px;" v-if="item_menu.title === 'Favoritos' && items_menu_datos[0].items.length <= 0">No hay rutas agregadas</span> -->
                <!-- </div> -->
                <span style="padding: 15px;font-size: 14px;text-align: center;color: #ffffff90;">Presiona las teclas Ctrl
                  + la tecla del 0 - 9 y navega en tus rutas favoritas</span>
              </div>

              <!-- <div v-if="menu.icon && item_menu.title === 'Favoritos'" class="box_menu_indicadores d-flex w-100 flex-column">

          <template v-for="indicador of indicadoresItems" :key="indicador">
            <div class="d-flex w-100 align-items-center justify-content-between" id="indicador" v-if="indicador.rules ? indicador.rules():true">
              <button v-button [routerLink]="indicador.link"  [routerLinkActive]="['indicador_activado']" @click="nav_menu_movil = false">
                <i class="mr-2" :class="indicador.icon"></i>
                {{indicador.name}}
              </button>
              
              <button class="indicador_menu_indicadores">
                {{indicador.value}}
              </button>
            </div>
          </template>
          
          <button v-icon-button style="height: 25px;width: 25px;display: flex;align-items: center;justify-content: center;overflow: hidden;" v-if="DatosUsuario.usuario == 'rnazario' || DatosUsuario.usuario == 'jtrujillo'" @click="panel_development()">
            <svg style="width: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M7.84 1.804A1 1 0 0 1 8.82 1h2.36a1 1 0 0 1 .98.804l.331 1.652a6.993 6.993 0 0 1 1.929 1.115l1.598-.54a1 1 0 0 1 1.186.447l1.18 2.044a1 1 0 0 1-.205 1.251l-1.267 1.113a7.047 7.047 0 0 1 0 2.228l1.267 1.113a1 1 0 0 1 .206 1.25l-1.18 2.045a1 1 0 0 1-1.187.447l-1.598-.54a6.993 6.993 0 0 1-1.929 1.115l-.33 1.652a1 1 0 0 1-.98.804H8.82a1 1 0 0 1-.98-.804l-.331-1.652a6.993 6.993 0 0 1-1.929-1.115l-1.598.54a1 1 0 0 1-1.186-.447l-1.18-2.044a1 1 0 0 1 .205-1.251l1.267-1.114a7.05 7.05 0 0 1 0-2.227L1.821 7.773a1 1 0 0 1-.206-1.25l1.18-2.045a1 1 0 0 1 1.187-.447l1.598.54A6.992 6.992 0 0 1 7.51 3.456l.33-1.652ZM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clipRule="evenodd" />
            </svg>
          </button>

        </div> -->

            </div>
          </div>
        </div>
      </template>
      <!-- </v-tabs> -->

    </div>

  </div>
</template>

<script>
import { state, recuperarLogin } from '@/funciones_globales';
import notifications_section from '../widgets/NotificationsSection.vue'

export default {
  components: {
    notifications_section
  },
  data: () => ({
    usuario: '',
    password: '',

    menu_login: false,
    show1: false,
    show2: true,
    activeButton: false,
    menu: [
      { title: 'Inicio', link: '#' },
      { title: 'Tienda', link: '#' },
      { title: 'Sucursales', link: '#' }
    ],
    prevScrollpos: window.scrollY,
    navBackgroundColor: '#fff',
    nav_menu_movil: false,

    // Sidbar Menu

    items_menu_datos: [],
    menuItems: [
      {
        title: 'Formatos',
        items: [
          {
            title: '',
            icon: '',
            items: [
              { name: 'Dashboard', link: '/dashboard' },
              // { name: 'Compras', link:'/comprasPendientes'},
              // { name: 'Gastos', link:'/gastos'},
              // { name: 'Embarques', link:'/movEmbarques', rules: () => this.sw_Admon_Embarques}
            ]
          },
        ]
      },
    ],
    deferredPrompt: null
  }),
  setup() {
    return {
      state
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('click', this.cerrarMenu);
    document.addEventListener('keydown', this.handleKeyDown);
    

   
    window.addEventListener('beforeinstallprompt', (e) => {

      // Prevent Chrome 67 and earlier from automatically showing the prompt

      e.preventDefault();

      // Stash the event so it can be triggered later.

      this.deferredPrompt = e;

      // Update UI notify the user they can install the PWA

      // document.getElementById('install-btn').disabled = false;

    });

  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    installar_app() {
      console.log('Instalar App');
      if (this.deferredPrompt) {

        // Show the prompt

        this.deferredPrompt.prompt();

        // Wait for the user to respond to the prompt

        this.deferredPrompt.userChoice.then((choiceResult) => {

          if (choiceResult.outcome === 'accepted') {

            console.log('User accepted the A2HS prompt');

          } else {

            console.log('User dismissed the A2HS prompt');

          }

          this.deferredPrompt = null;

        });

      } else {

        console.log('El evento beforeinstallprompt no se ha disparado aún');

      }
    },
    handleKeyDown(event) {
      if (event.ctrlKey && event.shiftKey) {
        this.nav_menu_movil = !this.nav_menu_movil
      }
    },

    logeo() {
      this.menu_login = false
      setTimeout(() => {
        if (this.usuario == 'Lizbeth' && this.password == 'Liz1234') {
          this.usuario = ''
          this.password = ''
          localStorage.setItem("sessionActive", 1)
          recuperarLogin()
        }

        if (this.usuario == 'Marisol' && this.password == 'Sol2024') {
          this.usuario = ''
          this.password = ''
          localStorage.setItem("sessionActive", 2)
          recuperarLogin()
        }
      }, 100);
    },
    logout() {
      this.menu_login = false
      setTimeout(() => {
        localStorage.clear();
        recuperarLogin()
      }, 100);

    },
    recuperarLogin,
    buscar_productos() {
      if (state.buscador_productos !== '') {
        const resultadosFiltrados = state.productos.filter(producto => {
          return producto.nombre_producto && producto.nombre_producto.toLowerCase().includes(state.buscador_productos.toLowerCase())
        })
        state.images_populares_filter = resultadosFiltrados
      } else {
        state.images_populares_filter = state.productos
      }

      // console.log('buscador_productos: ', state.buscador_productos);
    },
    cerrarMenu(event) {
      // Verificar si se hizo clic en una parte diferente al menú y al botón con ID "btn_open_menu"
      const isMenuClick = event.target.closest('#menu_sidebar');
      const isBtnClick = event.target.closest('#btn_open_menu');
      if (!isMenuClick && !isBtnClick && this.nav_menu_movil) {
        this.nav_menu_movil = false;
      }
    },
    setActive() {
      this.activeButton = true;
    },
    handleScroll() {
      const currentScrollPos = window.scrollY;
      // const scrollingDown = this.prevScrollpos < currentScrollPos;

      if (currentScrollPos > 90) {
        this.navBackgroundColor = 'rgba(255, 255, 255, 0.75)'; // Cambia el color de fondo cuando se desplaza 90px
      } else {
        this.navBackgroundColor = '#fff'; // Reestablece el color de fondo inicial
      }
    }



  }
}
</script>


<style>
.nav_bar {
  height: 75px;
  width: 100%;
  backdrop-filter: blur(5px);
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  z-index: 1 !important;
}

.box_img_enterprise {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  object-fit: cover;
}

.box_search {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  /* border: 1px solid #ffff00; */
  background: #00000005;
  padding: 0 15px 5px 15px;
  border-radius: 50px;
  /* background: red; */
  /* outline: 2px solid #ffda0050; */
  outline: 1px solid #00000015;
  position: relative;
}

.box_search:hover,
.box_search:focus-visible {
  outline: 2px solid #ff005a;
}

.btn_nav {
  height: 100px;
  color: #fff;
  display: grid;
  place-content: center;
  transition: all 0.1s ease;
  text-decoration: none;
  flex-grow: 1;
  width: 100px;
  padding: 5px 10px;
  border: 1px solid #ffffff10;
}

.btn_nav:hover,
.btn_nav_active {
  /* text-decoration: underline; */
  background: #ffffff15;
  text-underline-offset: 5px;
  /* font-weight: 900; */
}







/* Menu de Hamburguesa */
.menu-activador {
  width: 35px;
  height: 22px;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-activador-linea {
  height: 4px;
  width: 100%;
  border-radius: 50px;
  background-color: #008cff;
  opacity: 1;
  transform: translateY(0) rotate(0);
  transition: all 0.3s;
}

.clicked .menu-activador-linea:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.clicked .menu-activador-linea:nth-child(2) {
  opacity: 0;
}

.clicked .menu-activador-linea:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}


/*INICIO Acomodo menu*/
.box_menu {
  position: relative;
  width: 100%;
  min-width: 270px;
  max-width: 90%;
  height: 85%;
  border: 1px solid #ffffff15;
  padding: 15px;
  margin-top: -50px;
  box-sizing: border-box;
}

/* .box_menu:hover {
    box-shadow: 0px 5px 15px rgba(0,33,49,0.15);
    border: 1px solid #00d3ff;
    background: #ffffff10
} */

.box_menu .mat-button {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
  line-height: normal;
  display: flex;
  align-items: center !important;
  padding: 12px 8px;
  font-size: 13px
}

.box_menu .mat-button:hover {
  color: inherit;
  background: #ffffff10;
  text-decoration: none
}

.box_menu .mat-button {
  color: #ffffff !important
}

.box_menu div:hover .mat-button {
  color: #fff;
  background: #ffffff10;
  text-decoration: none
}


.box_menu div:hover .btn_delete_favoritos {
  background: #ffffff10;
  color: #fff !important;
  outline: 1px solid #eb4949 !important;
}

.box_menu div:hover .icon_delete_favoritos {
  color: #fff !important;
}


.box_menu .activado.mat-button {
  outline: 1px solid #ffffff10;
  background: #ffffff10;
}

.box_menu .activado {
  color: #fdd704 !important;
}

.icon_star {
  font-size: 15px;
  color: #ffffff70;
}

.icon_star:hover {
  color: #ffffff;
}

.box_menu_title {
  color: #fff;
  font-weight: 400 !important;
  width: 90% !important;
}

/*FIN Acomodo menu*/


.btn_delete_favoritos:hover .icon_delete_favoritos {
  color: #fff !important
}

.btn_delete_favoritos {
  background: transparent !important;
  border-radius: 5px;
  padding: 0;
  min-width: 20px;
  border: 0;
}

.btn_delete_favoritos:hover {
  background: #ffffff10;
  outline: 1px solid #eb4949 !important;
}


.box_menu_indicadores .mat-button {
  text-align: left;
}

.box_menu_indicadores .mat-button:hover {
  color: #fff;
  background: #ffffff10;
  text-decoration: none
}

.indicador_menu_indicadores {
  color: #fff;
  background: transparent !important;
  border-radius: 5px;
  border: 1px solid #ffffff10 !important;
  padding: 0;
  min-width: 20px;
}

#indicador .mat-button {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 30px !important;
  line-height: normal;
  display: flex;
  align-items: center !important;
  padding: 12px 8px;
  font-size: 12px
}

#indicador:hover .indicador_menu_indicadores,
#indicador:hover .mat-button {
  color: #fdd704;
  background: #ffffff10 !important;
}

.indicador_activado {
  outline: 1px solid #ffffff10;
  background: #ffffff10;
  color: #fdd704;
}

.btn_logout {
  color: hsla(203, 64%, 90%, 0.65) !important;
  cursor: pointer
}

.btn_logout:hover {
  color: #ffffff !important;
}

.v-slide-group__next--disabled,
.v-slide-group__prev--disabled,
.v-slide-group__next,
.v-slide-group__prev {
  display: none !important;
}
</style>
