<template>
  <div class="container">
    <h1>Registrar Venta</h1>
    <form @submit.prevent="registerSale">
      <div class="form-group">
        <label for="product">Producto:</label>
        <select id="product" v-model="sale.product" required>
          <option value="">Seleccione un producto</option>
          <option v-for="product in products" :key="product.id" :value="product.id">
            {{ product.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="quantity">Cantidad:</label>
        <input type="number" id="quantity" v-model="sale.quantity" required>
      </div>
      <div class="form-group">
        <label for="price">Precio:</label>
        <input type="number" id="price" v-model="sale.price" required>
      </div>
      <div class="form-group">
        <label for="date">Fecha:</label>
        <input type="date" id="date" v-model="sale.date" required>
      </div>
      <button type="submit">Registrar Venta</button>
    </form>
    <div v-if="saleRegistered" class="alert alert-success">
      Venta registrada con éxito!
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
      sale: {
        product: '',
        quantity: 0,
        price: 0,
        date: ''
      },
      saleRegistered: false
    }
  },
  mounted() {
    // Cargar productos desde la API o base de datos
    fetch('/api/products')
      .then(response => response.json())
      .then(data => {
        this.products = data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    registerSale() {
      // Enviar solicitud a la API o base de datos para registrar la venta
      fetch('/api/sales', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.sale)
      })
      .then(response => response.json())
      .then(() => {
        this.saleRegistered = true;
        this.sale = {
          product: '',
          quantity: 0,
          price: 0,
          date: ''
        };
      })
      .catch(error => {
        console.error(error);
      });
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
}

input, select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

button[type="submit"] {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #3e8e41;
}

.alert {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}
</style>