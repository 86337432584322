<template>
  <div class="contenedor justify-center gap-5"
    :style="$vuetify.display.width < 752 ? {padding: '95px 0 80px 0'}:{padding: '110px 30px 30px 30px',display: 'grid'}">

    <div class="d-flex w-100" :class="$vuetify.display.width < 470 ? 'gap-3 flex-column':'gap-4'"
      :style="$vuetify.display.width > 1281 ? {height: '330px',maxWidth: '1150px'}:$vuetify.display.width > 1012 ? {width: '70%',height: '300px'}:$vuetify.display.width > 752 ? {height: '250px',maxWidth: '1150px'}:$vuetify.display.width > 470 ? {padding: '0px 15px',width: '100%',maxHeight: '200px'}:{padding: '0px 15px',width: '100%'}">
      <!-- <img :src="img_banner" width="100%" height="350px" style="object-fit: cover;object-position: center;"/> -->
      <!-- <video :src="videoSource" type="video/mp4" autoplay muted loop style="width: 100%; height: 350px; object-fit: cover;">
        Tu navegador no soporta el elemento de video.
      </video> -->

      <carousel wrapAround :autoplay="3000" class="d-flex align-center" style="border-radius: 8px;overflow: hidden" :style="$vuetify.display.width > 1281 ? {width: '70%',height: '330px'}:$vuetify.display.width > 1012 ? {width: '70%',height: '300px'}:$vuetify.display.width > 752 ? {width: '70%',height: '250px'}:$vuetify.display.width > 470 ? {width: '70%',maxHeight: '200px'}:{width: '100%',height: '280px'}">
        <slide v-for="banner of imagenes_banner" :key="banner" style="cursor: pointer;" :style="$vuetify.display.width > 1281 ? {height: '330px'}:$vuetify.display.width > 1012 ? {height: '300px'}:$vuetify.display.width > 752 ? {height: '250px'}:$vuetify.display.width > 470 ? {width: '100%',maxHeight: '200px'}:{width: '100%',height: '280px'}">
          <img :src="banner.img" style="width:100%;height: 100%;border-radius: 8px;object-fit: cover; object-position: right">
        </slide>
      </carousel>

      <div class="d-flex flex-wrap flex-column" :class="$vuetify.display.width < 752 ? 'gap-3':'gap-4'"
      :style="$vuetify.display.width > 1281 ? {width: '30%',height: '100%',maxHeight: '330px'}:$vuetify.display.width > 1012 ? {width: '30%',height: '300px'}:$vuetify.display.width > 752 ? {width: '30%',maxHeight: '250px'}:$vuetify.display.width > 470 ? {width: '30%',maxHeight: '200px'}:{width: '100%',height: '280px'}">
        <div style="width: 100%;border-radius: 8px;max-height: 160px;height: 44%;" class="flex-grow-1">
          <img :src="img_banner_01" style="width:100%;height: 100%;border-radius: 8px;object-fit: cover;object-position: right;">
        </div>
        <div style="width: 100%;border-radius: 8px;overflow: hidden;max-height: 160px;height: 44%;" class="flex-grow-1">
          <img :src="img_banner_02" style="width:100%;height: 100%;border-radius: 8px;object-fit: cover;object-position: right;">
        </div>
      </div>

    </div>


    <div class="box_section_03 flex-column w-100" style="row-gap:25px;min-width: 50%;max-width: 1150px;overflow: hidden;"
      :style="$vuetify.display.width < 752 ? {borderRadius: '0', padding: '0 15px'}:$vuetify.display.width < 1281 ? {}:{}">
      <!-- <span style="font-size: 20px">Ranking</span> -->

      <div class="d-flex justify-space-between align-center">
         
        <div class="d-flex flex-column" style="font-family: Nunito !important;">
          <div class="d-flex align-center gap-1" style="line-height: 1;font-family: Nunito !important;">
            <span class="material-symbols-rounded" style="color:#ff005a">
              verified
            </span>
            <strong style="font-size: 24px;font-weight: 800;">
              DESTACADOS
            </strong>
          </div>
          <span style="color:#6184AA;font-size: 14px;font-weight: 500;">
            Revisa los descuentos de tus artículos destacados
          </span>
        </div>
      </div>

      <div class="d-flex justify-space-between gap-2" style="overflow: auto; padding-bottom: 10px;" v-if="tab_destacados == 'one'">
        <!-- <button type="button" class="btn_agregar_articulo" :class="$vuetify.display.width < 752 ? 'w-100':''" @click="add_articulo = true"><i class="fa-solid fa-plus mr-3"></i>Agregar Producto</button> -->
        <!-- <div class="d-flex flex-column" style="line-height: 25px;">
        <span style="font-size: 26px;font-weight: 500;color:#081c52">Nuevos Productos</span>
        <span style="color: #7995c0">Nuevos productos agregados recientemente</span>
       </div> -->


        <div :style="$vuetify.display.width < 752 ? {minWidth: '120px',width: '120px'}:{width: '24%'}" v-for="art in img_banner_03" :key="art">
          <img :src="art.img" style="width:100%;height: 100%;border-radius: 8px;">
        </div>

        <div class="d-flex gap-2 align-center" style="display:none !important">

          <div v-if="categoria_selected!=''">
            <v-chip style="color:#ff00b3" @click="categoria_selected = ''">
              <div>
                {{categoria_selected}}
              </div>
              <i class="fa-solid fa-xmark ml-4"></i>
            </v-chip>
          </div>
          <div class="d-flex gap-2">
            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Categoría</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index" @click="seleccionar_categoria(item.title)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Precio</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Estatus</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

      </div>
      
      
      <div class="d-flex justify-space-between" v-if="tab_destacados == 'two'">
        <!-- <button type="button" class="btn_agregar_articulo" :class="$vuetify.display.width < 752 ? 'w-100':''" @click="add_articulo = true"><i class="fa-solid fa-plus mr-3"></i>Agregar Producto</button> -->
        <!-- <div class="d-flex flex-column" style="line-height: 25px;">
        <span style="font-size: 26px;font-weight: 500;color:#081c52">Nuevos Productos</span>
        <span style="color: #7995c0">Nuevos productos agregados recientemente</span>
       </div> -->


        <div style="width: 250px;">
          <img :src="img_banner_04" style="width:100%;height: 100%;border-radius: 18px;">
        </div>

        <div class="d-flex gap-2 align-center" style="display:none !important">

          <div v-if="categoria_selected!=''">
            <v-chip style="color:#ff00b3" @click="categoria_selected = ''">
              <div>
                {{categoria_selected}}
              </div>
              <i class="fa-solid fa-xmark ml-4"></i>
            </v-chip>
          </div>
          <div class="d-flex gap-2">
            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Categoría</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index" @click="seleccionar_categoria(item.title)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Precio</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Estatus</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>

      <div value="one" :style="isSticky ? {paddingTop: '102.5px'}:{}">

        
      <div ref="stickySection" class="w-100 d-flex justify-center mb-9" :style="isSticky ? {position: 'fixed',top:' 75px',padding: '10px 15px',left:'0',zIndex: '996',background: 'rgba(255, 255, 255, 0.75)',backdropFilter: 'blur(5px)'}:{}">
        <div class="d-flex justify-space-between w-100 gap-3" style="max-width:1150px" :style="$vuetify.display.width < 752 ? {}:{padding: '0 2px'}">

          <button type="button" class="btn_agregar_articulo" :style="$vuetify.display.width < 600 ? {maxWidth: '45px'}:{width: '400px'}" :class="$vuetify.display.width < 600 ? 'w-100 px-3':''" @click="add_articulo = true"><i class="fa-solid fa-plus"></i><span v-if="$vuetify.display.width > 600" class="ml-3">Agregar Producto</span></button>

          <div class="d-flex gap-3 align-center" :class="$vuetify.display.width < 752 ? 'w-100':''">
            <button type="button" class="btn_importar_articulo" :style="$vuetify.display.width < 752 ? {padding: '7px 20px'}:{minWidth: '250px'}" :class="$vuetify.display.width < 752 ? 'w-100':''" @click="$refs.inputFile.click();"><i class="fa-solid fa-plus mr-3" v-if="$vuetify.display.width > 752"></i>Importar <span v-if="$vuetify.display.width > 752">Productos</span></button>
            <button type="button" class="btn_guardar_importar_articulo" :style="$vuetify.display.width < 752 ? {padding: '7px 20px'}:{}" :class="$vuetify.display.width < 752 ? 'w-100':''" @click="agregar_datos_producto()" v-if="calcularStock > 0">
              Publicar 
              <strong class="ml-2" style="font-size: 19px;color: #fff;font-weight: 800;">
                {{ calcularStock }}
              </strong>
            </button>
          </div>

          <input type="file" ref="inputFile" hidden @change="importarExcel" />

        </div>
      </div>


        <div class="mb-7 d-flex justify-space-between align-center">

          <div class="d-flex gap-2 align-center">
            <span class="material-icons" style="color: #ff005a">
              auto_awesome
              </span>
            <strong style="font-size: 19px;font-weight: 800;">
              ARTÍCULOS POR AGREGAR
            </strong>
          </div>
            
          <!-- <div class="d-flex gap-2" v-if="calcularStock > 0">
            <strong style="font-size: 19px;">
              ARTICULOS: 
            </strong>
            <strong style="font-size: 19px;color: #ff005a;font-weight: 800;">
              {{ calcularStock }}
            </strong>
          </div> -->


        </div>

        

        <div class="d-flex flex-wrap" style="overflow: hidden;margin: 0 -6px;"> 
          <div v-for="producto of productos_importados" style="padding: 3px 7px"  :key="producto" :style="$vuetify.display.width > 1009 ? {width: '20%'}:$vuetify.display.width > 400 ? {width: '25%'}:{width: '50%'}">





            <carousel wrapAround style="border-radius: 15px;overflow:hidden" :mouseDrag="producto.imagenes_productos.length > 1 ? true:false" :touchDrag="producto.imagenes_productos.length > 1 ? true:false">
                  <slide v-for="imagen of producto.imagenes_productos" :key="imagen">

                   
                    <v-hover>
                      <template v-slot:default="{ isHovering, props }">

                        <div v-bind="props" class="box_img">
                          <img class="w-100" style="height: 100%;object-fit: cover;" :src="imagen">
                          <v-btn v-show="isHovering" v-if="state.sessionActive == 1" icon="$vuetify" density="compact" variant="text" class="bottom_icon" @click="editProducto(producto)" style="position: absolute;top: 10px;right: 10px;">
                            <i class="fa-solid fa-pen" style="color: #008cff;"></i>
                          </v-btn>

                          <span class="d-flex align-center justify-center" style="padding: 2px 10px;border-radius: 5px;outline: 1px solid #000000ef;color:#fff;background: #00000080;position: absolute;bottom: 15px;right: 10px;min-width: 30px;min-height: 30px;">{{ producto.stock_producto }}</span>

                        </div>
                      </template>
                    </v-hover>



                  </slide>
              </carousel>

            <div class="py-1">
              <div class="d-flex flex-column">

                <p class="d-flex flex-column justify-center">
                  <h style="font-weight: 500;text-overflow: ellipsis;white-space: nowrap;width: 95%;overflow: hidden;"
                    :style="$vuetify.display.width < 752 ? {fontSize: '11px'}:{fontSize: 'small'}">{{
                    producto.nombre_producto }}</h>
                </p>
                <div class="d-flex justify-space-between pb-1 gap-1">
                  <p class="d-flex flex-column justify-space-around ma-0" style="line-height:1.2">
                    <span style="font-size:11px;color:gray; font-weight: 500;">{{ producto.marca_producto }}</span>
                    <span style="width:10px;height: 10px;border-radius: 50px;" :style="{background: producto.color_producto}"></span>
                  </p>
                  <p class="d-flex flex-column ma-0" style="line-height:1.2;height:31px">
                    <span style="font-weight: 600;color:red;font-size:14px" v-if="producto.descuento_producto">
                      {{ numberFormat.format(producto.precio_producto * (1 - (producto.descuento_producto / 100))) }}
                    </span>
                    <span
                      :style="producto.descuento_producto ? { textDecoration: 'line-through', color: 'gray', fontWeight: '400', fontSize: '12px' } : { fontWeight: '600',color:'red', fontSize: '14px' }">
                      {{ numberFormat.format(producto.precio_producto) }}
                    </span>
                  </p>
                </div>

              </div>
              <!-- <div class="d-flex gap-2">
                  <v-btn icon="$vuetify" density="compact" variant="text" class="bottom_icon" @click="mostrar_datos">
                    <i class="fa-regular fa-heart"></i>
                  </v-btn>
                  <v-btn density="compact" variant="text" class="btn_agregar">Agregar</v-btn>
                </div> -->
            </div>

          </div>
        </div>

        <v-alert v-if="productos_importados <= 0" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima, at placeat totam, magni doloremque veniam neque porro libero rerum unde voluptatem!" title="Alert title" type="info" variant="tonal"></v-alert>
        
        
        
        
        <div class="d-flex flex-wrap" style="overflow: hidden;margin: 0 -6px;"> 
          <div v-for="(sucursal, index) in state.datos_sucursales" :key="index" style="padding: 3px 7px" :style="$vuetify.display.width > 1009 ? {width: '20%'}:$vuetify.display.width > 400 ? {width: '25%'}:{width: '50%'}">





            <!-- <carousel wrapAround style="border-radius: 15px;overflow:hidden" :mouseDrag="sucursal.imagenes_sucursal.length > 1 ? true:false" :touchDrag="sucursal.imagenes_sucursal.length > 1 ? true:false">
                  <slide v-for="imagen of sucursal.imagenes_sucursal" :key="imagen">

                   
                    <v-hover>
                      <template v-slot:default="{ isHovering, props }">

                        <div v-bind="props" class="box_img">
                          <img class="w-100" :src="imagen">
                          <v-btn v-show="isHovering" v-if="state.sessionActive == 1" icon="$vuetify" density="compact" variant="text" class="bottom_icon" style="position: absolute;top: 10px;right: 10px;">
                            <i class="fa-solid fa-pen" style="color: #008cff;"></i>
                          </v-btn>

                          <span class="d-flex align-center justify-center" style="padding: 2px 10px;border-radius: 5px;outline: 1px solid #000000ef;color:#fff;background: #00000080;position: absolute;bottom: 15px;right: 10px;min-width: 30px;min-height: 30px;">{{ producto.stock_producto }}</span>

                        </div>
                      </template>
                    </v-hover>



                  </slide>
              </carousel>

            <div class="py-1">
              <div class="d-flex flex-column">

                <p class="d-flex flex-column justify-center">
                  <h style="font-weight: 500;text-overflow: ellipsis;white-space: nowrap;width: 95%;overflow: hidden;"
                    :style="$vuetify.display.width < 752 ? {fontSize: '11px'}:{fontSize: 'small'}">{{
                    producto.nombre_producto }}</h>
                </p>
                <div class="d-flex justify-space-between pb-1 gap-1">
                  <p class="d-flex flex-column justify-space-around ma-0" style="line-height:1.2">
                    <span style="font-size:11px;color:gray; font-weight: 500;">{{ producto.marca_producto }}</span>
                    <span style="width:10px;height: 10px;border-radius: 50px;" :style="{background: producto.color_producto}"></span>
                  </p>
                  <p class="d-flex flex-column ma-0" style="line-height:1.2;height:31px">
                    <span style="font-weight: 600;color:red;font-size:14px" v-if="producto.descuento_producto">
                      {{ numberFormat.format(producto.precio_producto * (1 - (producto.descuento_producto / 100))) }}
                    </span>
                    <span
                      :style="producto.descuento_producto ? { textDecoration: 'line-through', color: 'gray', fontWeight: '400', fontSize: '12px' } : { fontWeight: '600',color:'red', fontSize: '14px' }">
                      {{ numberFormat.format(producto.precio_producto) }}
                    </span>
                  </p>
                </div>

              </div>
              
            </div> -->

          </div>
        </div>
        
        
        
        
        
        <!-- <v-form ref="form" v-model="valid" class="mt-10">
      <v-row v-for="(sucursal, index) in state.datos_sucursales" :key="index">
        <v-col cols="12" md="6">
          <v-text-field variant="outlined"
            v-model="sucursal.clave_sucursal"
            label="Clave Sucursal"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field variant="outlined"
            v-model="sucursal.nombre_sucursal"
            label="Nombre Sucursal"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field variant="outlined"
            v-model="sucursal.direccion_sucursal"
            label="Dirección Sucursal"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field variant="outlined"
            v-model="sucursal.ciudad_sucursal"
            label="Ciudad Sucursal"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field variant="outlined"
            v-model="sucursal.estado_sucursal"
            label="Estado Sucursal"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field variant="outlined"
            v-model="sucursal.pais_sucursal"
            label="País Sucursal"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field variant="outlined"
            v-model="sucursal.telefono_sucursal"
            label="Teléfono Sucursal"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field variant="outlined"
            v-model="sucursal.responsable_sucursal"
            label="Responsable Sucursal"
          ></v-text-field>
        </v-col>
      </v-row>
      </v-form> -->

      <v-row v-for="(sucursal, index) in state.datos_sucursales" :key="index" @click="ver_sucursal(sucursal)">
        <v-col cols="12">
          <div class="py-1">
            <div class="d-flex flex-column">
              <!-- Clave Sucursal -->
              <p class="d-flex flex-column justify-center">
                <h style="font-weight: 500; text-overflow: ellipsis; white-space: nowrap; width: 95%; overflow: hidden;"
                  :style="$vuetify.display.width < 752 ? { fontSize: '11px' } : { fontSize: 'small' }">
                  {{ sucursal.clave_sucursal }}
                </h>
              </p>
              <!-- Nombre y Dirección -->
              <div class="d-flex justify-space-between pb-1 gap-1">
                <p class="d-flex flex-column justify-space-around ma-0" style="line-height: 1.2">
                  <span style="font-size: 11px; color: gray; font-weight: 500;">{{ sucursal.nombre_sucursal }}</span>
                  <span style="width: 10px; height: 10px; border-radius: 50px;" :style="{ background: '#CCCCCC' }"></span>
                </p>
                <p class="d-flex flex-column ma-0" style="line-height: 1.2; height: 31px;">
                  <span style="font-weight: 600; color: red; font-size: 14px;">{{ sucursal.direccion_sucursal }}</span>
                </p>
              </div>
              <!-- Ciudad, Estado y País -->
              <div class="d-flex justify-space-between pb-1 gap-1">
                <p class="d-flex flex-column justify-space-around ma-0" style="line-height: 1.2">
                  <span style="font-size: 11px; color: gray; font-weight: 500;">{{ sucursal.ciudad_sucursal }}, {{ sucursal.estado_sucursal }}</span>
                  <span style="width: 10px; height: 10px; border-radius: 50px;" :style="{ background: '#CCCCCC' }"></span>
                </p>
                <p class="d-flex flex-column ma-0" style="line-height: 1.2; height: 31px;">
                  <span style="font-weight: 600; color: red; font-size: 14px;">{{ sucursal.pais_sucursal }}</span>
                </p>
              </div>
              <!-- Teléfono y Responsable -->
              <div class="d-flex justify-space-between pb-1 gap-1">
                <p class="d-flex flex-column justify-space-around ma-0" style="line-height: 1.2">
                  <span style="font-size: 11px; color: gray; font-weight: 500;">{{ sucursal.telefono_sucursal }}</span>
                  <span style="width: 10px; height: 10px; border-radius: 50px;" :style="{ background: '#CCCCCC' }"></span>
                </p>
                <p class="d-flex flex-column ma-0" style="line-height: 1.2; height: 31px;">
                  <span style="font-weight: 600; color: red; font-size: 14px;">{{ sucursal.responsable_sucursal }}</span>
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      </div>
    </div>


  </div>

  
  <v-dialog v-model="modal_ver_sucursal" fullscreen :scrim="false" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark style="background: #000" class="d-flex justify-space-between gap-2">

        <v-btn icon variant="plain" style="color: #fff" @click="modal_ver_sucursal = false">
          <i class="fa-solid fa-angle-left" style="color:#fff"></i>
        </v-btn>

        <v-toolbar-title style="color: #fff;padding-right: 20px;"
          :style="$vuetify.display.width < 752 ? {textAlign: 'right'}:{textAlign: 'center'}">Nuevo
          Producto</v-toolbar-title>

      </v-toolbar>


      <div class="d-flex flex-wrap align-center h-100">
        
        <div class="flex-grow-1">
          
          <img class="w-100" style="max-width: 670px !important" :src="require('../assets/img/banner_galeria_sucursales.jpg')">
          
        </div>

        
        <div class="d-flex flex-column flex-grow-1" style="padding:0 20px">

          <div class="d-flex">
            <h style="font-size: 36px;
  font-weight: 900;
  border-bottom: 5px solid #27B4FA;line-height: 1;
  padding: 15px 0;">Hacienda Echeveste</h>
          </div>
          <div style="padding:25px 0;color:#8694A3">
            Calle Tabiqueros de Echeveste, Hacienda Echeveste
          </div>
          <div class="d-flex flex-wrap gap-2" style="color:#607487">
            <div class="flex-grow-1">
              <spanp>León, Guanajuato</spanp>
              <p>477 268 9595</p>
            </div>
            <div class="flex-grow-1">
              <p>Responsable</p>
              <p>Marisol</p>
            </div>

          </div>

          </div>
      </div>

      
    </v-card>
  </v-dialog>


  <v-dialog v-model="add_articulo" fullscreen :scrim="false" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark style="background: #000" class="d-flex justify-space-between gap-2">

        <v-btn icon variant="plain" style="color: #fff" @click="add_articulo = false">
          <i class="fa-solid fa-angle-left" style="color:#fff"></i>
        </v-btn>

        <v-toolbar-title style="color: #fff;padding-right: 20px;"
          :style="$vuetify.display.width < 752 ? {textAlign: 'right'}:{textAlign: 'center'}">Nuevo
          Producto</v-toolbar-title>

      </v-toolbar>
      <v-list lines="two" subheader :class="$vuetify.display.width > 752 ? 'd-flex justify-center h-100':' pa-0'">
        <AddArticulo :style="$vuetify.display.width > 752 ? {maxWidth: '1220px'}:{}" />
      </v-list>
    </v-card>
  </v-dialog>

  <v-dialog v-model="actualizar_articulo" fullscreen :scrim="false" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark style="background: #000" class="d-flex justify-space-between gap-2">

        <v-btn icon variant="plain" style="color: #fff" @click="actualizar_articulo = false">
          <i class="fa-solid fa-angle-left" style="color:#fff"></i>
        </v-btn>

        <v-toolbar-title style="color: #fff;padding-right: 20px;"
          :style="$vuetify.display.width < 752 ? {textAlign: 'right'}:{textAlign: 'center'}">{{
          data_edit_producto.nombre_producto }}</v-toolbar-title>

      </v-toolbar>
      <v-list lines="two" subheader :class="$vuetify.display.width > 752 ? 'd-flex justify-center h-100':' pa-0'">
        <EditProducto :producto="data_edit_producto" :style="$vuetify.display.width > 752 ? {maxWidth: '1220px'}:{}"
          @cerrar_edit_producto="actualizar_articulo = false" />
      </v-list>
    </v-card>
  </v-dialog>

  
  <div v-if="snackbar" style="display:flex;color: #fff; background: #4CAF50;padding: 10px 15px;position: fixed;bottom: 30px;right: 30px;">
          <div class="d-flex gap-2 align-center">
            <span class="material-symbols-outlined">
              task_alt
            </span>
            I'm a success alert.
            <v-btn class="ml-5 pa-0" style="color: #fff;min-width: 30px;" variant="text" @click="snackbar = false">
            <span class="material-symbols-outlined">
              close
            </span>
            </v-btn>
          </div>
        </div>
</template>


<script>
import AddArticulo from '../components/AddArticulo.vue'
import EditProducto from '../components/EditProducto.vue'
import * as XLSX from 'xlsx';
//
import axios from 'axios';


import video from "@/assets/img/video_backgrond.mp4";
import { state, mostrar_productos, mostrar_sucursales } from '../funciones_globales'

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'


export default ({

  components: {
    Carousel,
    Slide,
    AddArticulo,
    EditProducto
  },
  data: () => ({
    
    snackbar: false,
    
    categoria_selected: '',
    productos_importados: [],

    imagenes_banner: [
      {img: require('../assets/img/banner_principal_01.jpg')},
      {img: require('../assets/img/banner_principal_02.jpg')},
      {img: require('../assets/img/banner_principal_03.jpg')}
    ],
    
    modal_ver_sucursal: false,

    add_articulo: false,
    actualizar_articulo: false,
    data_edit_producto: null,

    numberFormat: new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 0
    }),
    img_banner_01: require('../assets/img/banner_02.webp'),
    img_banner_02: require('../assets/img/banner_03.jpg'),
    img_banner_03: [
      {img: require('../assets/img/destacados_01.jpg')},
      {img: require('../assets/img/promocion_destacado_01.jpg')},
      {img: require('../assets/img/destacados_02.jpg')},
      {img: require('../assets/img/promocion_destacado_02.jpg')},
      {img: require('../assets/img/destacados_03.jpg')},
      {img: require('../assets/img/promocion_destacado_03.jpg')}
    ],
    img_banner_04: require('../assets/img/banner_04.jpg'),
    videoSource: video,
    // chartConfig
    nombresMeses: [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    mes_actual: null,
    datos_ventas: [
      { mes: 'Ene', venta: 50 },
      { mes: 'Feb', venta: 55 },
      { mes: 'Mar', venta: 40 },
      { mes: 'Abr', venta: 45 },
      { mes: 'May', venta: 30 },
      { mes: 'Jun', venta: 25 },
      { mes: 'Jul', venta: 20 },
      { mes: 'Ago', venta: 50 },
      { mes: 'Sep', venta: 35 },
      { mes: 'Oct', venta: 45 },
      { mes: 'Nov', venta: 30 },
      { mes: 'Dic', venta: 55 }
    ],
    venta_actual: 7856.87,
    items: [
      {
        title: 'Paquetes',
        value: 1,
        props: {
          appendIcon: 'mdi-chevron-right',
          color: '#33adff',
        },
      },
      {
        title: 'Pedidos',
        value: 2,
        props: {
          appendIcon: 'mdi-chevron-right',
          color: '#33adff',
        },
      },
      {
        title: 'Apartados',
        value: 3,
        props: {
          appendIcon: 'mdi-chevron-right',
          color: '#33adff',
        },
      },
    ],
 pedidos: [
          {
            pedido: 'GBDC104520045801',
            fecha_pedido: '11/02/2024',
            paquetes: 19,
            paquetes_entregados: 5,
            paquetes_pendientes: 14,
            paqueteria: 'Estafeta'
          },
          {
            pedido: 'GBDC125520045887',
            fecha_pedido: '09/02/2024',
            paquetes: 11,
            paquetes_entregados: 3,
            paquetes_pendientes: 8,
            paqueteria: 'J&T'
          },
          {
            pedido: 'GBDC104527445836',
            fecha_pedido: '07/02/2024',
            paquetes: 5,
            paquetes_entregados: 2,
            paquetes_pendientes: 3,
            paqueteria: 'Imile'
          },
          {
            pedido: 'GBDC104583558045',
            fecha_pedido: '07/02/2024',
            paquetes: 13,
            paquetes_entregados: 9,
            paquetes_pendientes: 4,
            paqueteria: 'Estafeta'
          },
          {
            pedido: 'GBDC1685420045887',
            fecha_pedido: '03/02/2024',
            paquetes: 10,
            paquetes_entregados: 10,
            paquetes_pendientes: 0,
            paqueteria: 'Imile'
          },
          {
            pedido: 'GBDG584520049801',
            fecha_pedido: '03/02/2024',
            paquetes: 27,
            paquetes_entregados: 27,
            paquetes_pendientes: 0,
            paqueteria: 'AMPM'
          }
        ],
        tab_destacados: 'one',
        
      isSticky: false,
      stickyOffset: 0
  }),
  setup() {
    return {
      state
    }
  },
  computed: {
    venta_total() {

      const formattedValue = this.numberFormat.format(this.venta_actual);
      const decimalPart = this.venta_actual % 1 === 0 ? '' : (this.venta_actual % 1).toFixed(2).substring(1);

      return {
        entero: formattedValue,
        decimal: decimalPart
      }
    },
    calcularStock(){
      let totalStockArticulos = 0
      this.productos_importados.forEach(element => {
        totalStockArticulos = totalStockArticulos + element.stock_producto
      });
      return totalStockArticulos
    }
  },
  created() {
    this.mostrar_productos()
    this.mostrar_sucursales()
    // let mes_n_actual = new Date()
    // console.log('mes_n_actual: ', mes_n_actual);
    this.mes_actual = this.nombresMeses[new Date().getMonth()]
  },
  mounted() {
    this.stickyOffset = this.$refs.stickySection.offsetTop;
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeMount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    
    mostrar_sucursales,
    agregar_datos_producto() {

      

    //   const depuratedProductos = this.productos_importados.map(item => {
    //     console.log('item: ', Object.keys(item));
    //   const target = item['<target>'];
    //   if (target && typeof target === 'object' && Object.keys(target).length > 0) {
    //     return { ...target };
    //   } else {
    //     return null;
    //   }
    // }).filter(item => item !== null);

    // console.log('Depurated productos:', depuratedProductos);


    const depuratedProductos = this.productos_importados.map(item => {
    const target = item
    if (target && typeof target === 'object' && Object.keys(target).length > 0) {
      return { ...target };
    } else {
      return null;
    }
  }).filter(item => item !== null);

  // console.log('Depurated productos:', depuratedProductos);




    let datos_importados = []
    let datos_importados_actualizar = []


    depuratedProductos.forEach(element => {
      // console.log('element: ', element['Proxy']);

      let producto_existe = state.productos.find(item => item.key_producto === element.key_producto)
      // let producto_existe_importar = datos_importados_actualizar.find(item => item.key_producto === element.key_producto)

      if(producto_existe){
        if (element.nombre_producto.length > 550) {
            element.nombre_producto = element.nombre_producto.substring(0, 550);
          }
        if (element.detalles_producto.length > 550) {
            element.detalles_producto = element.detalles_producto.substring(0, 550);
          }
        element.sucursales_producto = JSON.parse(element.sucursales_producto)
        element.stock_producto += producto_existe.stock_producto

        datos_importados_actualizar.push(element)
      }else{
        if (element.nombre_producto.length > 550) {
            element.nombre_producto = element.nombre_producto.substring(0, 550);
          }
        if (element.detalles_producto.length > 550) {
            element.detalles_producto = element.detalles_producto.substring(0, 550);
          }
        element.sucursales_producto = JSON.parse(element.sucursales_producto)
        datos_importados.push(element)
      }

    });



    if(datos_importados.length > 0){
      
      console.log('datos_importados: ', datos_importados);

      axios.post('https://nexus-ux.com/microservicio/public/api/producto/insertarProducto', datos_importados).then( () => {
        
        this.productos_importados = []      
        this.mostrar_productos()
          this.notificacionSuccess()
        
      }).catch(error => {
        console.error("Error uploading the image:", error);
      });
    }
    
    if(datos_importados_actualizar.length > 0){
      console.log('datos_importados_actualizar: ', datos_importados_actualizar);

      axios.put('https://nexus-ux.com/microservicio/public/api/producto/updateProducto', datos_importados_actualizar)
          .then(() => {
            this.productos_importados = []
            this.mostrar_productos()
            this.notificacionSuccess()
            
          })
          .catch(error => {
              console.error("Error uploading the image:", error);
          });
    }

  },

  ver_sucursal(sucursal){
    console.log('sucursal: ', sucursal);
    this.modal_ver_sucursal = true
  },

  notificacionSuccess(){
    
    this.snackbar = true;

    setTimeout(() => {
      this.snackbar = false;
    }, 3000); // 3000 milisegundos = 3 segundos
  },
    handleScroll() {
      this.isSticky = window.pageYOffset >= (this.stickyOffset + 118);
    },
    mostrar_productos,
    editProducto(producto){
      this.actualizar_articulo = true
      this.data_edit_producto = producto
    },
    seleccionar_categoria(categoria){
      this.categoria_selected = categoria
    },
    importarExcel(event) {
      // console.log('event: ', event);
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.processData(jsonData);
      };

      reader.readAsArrayBuffer(file);
    },
    processData(jsonData) {
      if (jsonData.length === 0) return;

      const headers = jsonData[0];
      const rows = jsonData.slice(1);

      console.log('rows: ', rows);
      this.dataArray = rows.map(row => {
        let obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });

      let datos_con_imagenes = []
      
      this.dataArray.forEach(element => {
        if(element.key_producto != undefined){
          let articulo_existe = this.productos_importados.find(item => item.key_producto === element.key_producto) 
          if(articulo_existe != undefined){

            if(articulo_existe.key_producto === element.key_producto){
              let validacion = datos_con_imagenes.some(item => item.imagenes_productos != element.imagenes_productos)
              if(validacion == false){
                datos_con_imagenes.push(element.imagenes_productos)
                element.imagenes_productos = datos_con_imagenes 
              }
            }
            articulo_existe.stock_producto = articulo_existe.stock_producto + 1
            
          }else{
            datos_con_imagenes = []
            datos_con_imagenes.push(element.imagenes_productos)
            element.imagenes_productos = datos_con_imagenes 
            this.productos_importados.push(element)
          }
        }
        
      });

      console.log(this.productos_importados);
    }
  }

});
</script>

<style scoped>
.contenedor {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.box_img_banner {
  min-height: 200px;
  max-height: 350px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 25px rgba(100, 150, 200, 0.2);
  overflow: hidden;
}

.box_section_01 {
  display: flex;
  height: 210px;
  flex-grow: 1;
  max-width: 370px;
  background: linear-gradient(to right, #33adff, #0502e1);
  border-radius: 15px;
  box-shadow: 0px 5px 25px rgba(100, 150, 200, 0.2);
  position: relative;
  overflow: hidden
}

.box_section_02 {
  display: flex;
  flex-grow: 1;
  height: 210px;
  padding: 10px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 25px rgba(100, 150, 200, 0.2);
}

.box_section_03 {
  display: flex;
  /* background: #fff; */
  border-radius: 15px;
  /* box-shadow: 0px 5px 25px rgba(100, 150, 200, 0.2); */
}

.waves {
  opacity: .15;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;

  min-height: 100px;
  max-height: 150px;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -5s;
  animation-duration: 10s;
}

.parallax>use:nth-child(2) {
  animation-delay: -10s;
  animation-duration: 25s;
}

.parallax>use:nth-child(3) {
  animation-delay: -15s;
  animation-duration: 15s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 30s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}

.image-container {
  position: relative;
}

.box_img {
  width:100%;
  position: relative;
  height: 300px;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.contenido_articulo {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s, visibility 0.6s;
}

.box_img:hover .contenido_articulo {
  visibility: visible;
  opacity: 1;
  background: #00000050;
  top: 0;
}

.btn_opciones {
  border-radius: 15px !important;
  border: 2px solid #00000020;
  color: #000000 !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(188.5px, 216px));
}

#box_tabs_inicio_articulos .v-slide-group__content {
  column-gap: 10px;
}

.bottom_icon {
  background: #ffffffd3;
  width: 35px;
  height: 35px;
  font-size: 16px;
  /* border: 2px solid #00000030; */
}

.btn_agregar {
  background: #000;
  color: #fff !important;
  text-transform: none;
  letter-spacing: normal;
  border-radius: 0;
  flex-grow: 1;
}

.btn_agregar_articulo {
  background-color: #000000;
  color: #fff !important;
  padding: 8px 40px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.btn_agregar_articulo:hover {
  background-color: #131313;
}
.active-tab {
    background: #000;
    color: #fff !important;
}

.btn_importar_articulo{
  outline: 2px solid #000000;
  color: #000 !important;
  padding: 8px 30px;
  cursor: pointer;
  font-size: 16px;
}
.btn_guardar_importar_articulo{
  background: #008cea;
  color: #fff !important;
  padding: 8px 30px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>