<template>
  <v-container>
    <v-file-input
      label="Seleccionar archivo Excel"
      @change="handleFileUpload"
    ></v-file-input>
    <v-select
      v-if="headers.length"
      :items="headers"
      label="Seleccionar campo a editar"
      v-model="selectedField"
    ></v-select>
    <v-text-field
      v-if="selectedField"
      label="Texto a eliminar"
      v-model="textToRemove"
    ></v-text-field>
    <v-btn @click="processData">
      Iniciar
    </v-btn>
    <v-btn @click="processData_especifica">
      Quitar $MXN
    </v-btn>
    <v-btn @click="generar_importador()">
      Generar Importador
    </v-btn>
    <v-btn @click="exportToExcel" :disabled="!processedData.length">
      Exportar a Excel
    </v-btn>
  </v-container>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      headers: [],
      selectedField: "",
      textToRemove: "",
      data: [],
      processedData: [],
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.headers = jsonData[0];
        this.data = jsonData
      };

      reader.readAsArrayBuffer(file);
    },
    processData() {
      const index = this.headers.indexOf(this.selectedField);
      if (index === -1) return;

      this.processedData = this.data.map((row) => {
        if (typeof row[index] === 'string') {
          row[index] = row[index].replace(this.textToRemove, "");
        }
        return row;
      });
      console.log('processedData: ', this.processedData);
    },
    generar_importador() {
      // Verificación de los encabezados y datos de entrada
      console.log("data:", this.data);

      this.importData = this.data.map((row) => {
        return {
          imagenes_productos: row[4],
          nombre_producto: row[7],
          detalles_producto: row[7],
          precio_producto: row[6],
          descuento_producto: 0,
          stock_producto: 1,
          id_pedido: row[0],
          sku_producto: row[9],
          key_producto: row[9],
          sucursales_producto: JSON.stringify(["HETE202", "VLL320A"]),
          categoria_producto: 'Mujer',
          marca_producto: 'Shein',
          color_producto: 'Negro',
          medida_producto: 'M',
          subcategoria_producto: 'Ropa',
          ranking_producto: 0,
          status_producto: 'Proxímamente',
          created_at: '2024-07-10 10:34:14',
          updated_at: '2024-07-10 10:34:14'
        };
      });

      // Verificación de los datos generados para importación
      console.log("Import Data:", this.importData);

      // Generar la hoja de Excel
      const ws = XLSX.utils.json_to_sheet(this.importData);

      // Crear el libro de trabajo y agregar la hoja
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Productos');

      // Generar el archivo Excel y descargarlo
      XLSX.writeFile(wb, 'productos.xlsx');
    },
    
    processData_especifica() {
      const index = this.headers.indexOf(this.selectedField);
      if (index === -1) return;

      const regex = /\$MXN\d+(\.\d{2})?/g;

      this.processedData = this.data.map((row) => {
        if (typeof row[index] === 'string') {
          row[index] = row[index].replace(regex, "").trim();
        }
        return row;
      });
      console.log('processedData: ', this.processedData);
    },
    exportToExcel() {
      const worksheet = XLSX.utils.aoa_to_sheet([this.headers, ...this.processedData]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(workbook, "ProcessedData.xlsx");
    },
  },
};
</script>

<style>
/* Agrega estilos personalizados si es necesario */
</style>
