<template>
  <div class="contenedor justify-center gap-5"
    :style="$vuetify.display.width < 752 ? { padding: '95px 0 80px 0' } : { padding: '110px 30px 30px 30px', display: 'grid' }">

    <div class="d-flex w-100" :class="$vuetify.display.width < 470 ? 'gap-3 flex-column' : 'gap-4'"
      :style="$vuetify.display.width > 1281 ? { height: '330px', maxWidth: '1150px' } : $vuetify.display.width > 1012 ? { width: '70%', height: '300px' } : $vuetify.display.width > 752 ? { height: '250px', maxWidth: '1150px' } : $vuetify.display.width > 470 ? { padding: '0px 15px', width: '100%', maxHeight: '200px' } : { padding: '0px 15px', width: '100%' }">
      <!-- <img :src="img_banner" width="100%" height="350px" style="object-fit: cover;object-position: center;"/> -->
      <!-- <video :src="videoSource" type="video/mp4" autoplay muted loop style="width: 100%; height: 350px; object-fit: cover;">
        Tu navegador no soporta el elemento de video.
      </video> -->

      <carousel wrapAround :autoplay="3000" class="d-flex align-center" style="border-radius: 8px;overflow: hidden"
        :style="$vuetify.display.width > 1281 ? { width: '70%', height: '330px' } : $vuetify.display.width > 1012 ? { width: '70%', height: '300px' } : $vuetify.display.width > 752 ? { width: '70%', height: '250px' } : $vuetify.display.width > 470 ? { width: '70%', maxHeight: '200px' } : { width: '100%', height: '280px' }">
        <slide v-for="banner of imagenes_banner" :key="banner" style="cursor: pointer;"
          :style="$vuetify.display.width > 1281 ? { height: '330px' } : $vuetify.display.width > 1012 ? { height: '300px' } : $vuetify.display.width > 752 ? { height: '250px' } : $vuetify.display.width > 470 ? { width: '100%', maxHeight: '200px' } : { width: '100%', height: '280px' }">
          <img :src="banner.img"
            style="width:100%;height: 100%;border-radius: 8px;object-fit: cover; object-position: right">
        </slide>
      </carousel>

      <div class="d-flex flex-wrap flex-column" :class="$vuetify.display.width < 752 ? 'gap-3' : 'gap-4'"
        :style="$vuetify.display.width > 1281 ? { width: '30%', height: '100%', maxHeight: '330px' } : $vuetify.display.width > 1012 ? { width: '30%', height: '300px' } : $vuetify.display.width > 752 ? { width: '30%', maxHeight: '250px' } : $vuetify.display.width > 470 ? { width: '30%', maxHeight: '200px' } : { width: '100%', height: '280px' }">
        <div style="width: 100%;border-radius: 8px;max-height: 160px;height: 44%;" class="flex-grow-1">
          <img :src="img_banner_01"
            style="width:100%;height: 100%;border-radius: 8px;object-fit: cover;object-position: right;">
        </div>
        <div style="width: 100%;border-radius: 8px;overflow: hidden;max-height: 160px;height: 44%;" class="flex-grow-1">
          <img :src="img_banner_02"
            style="width:100%;height: 100%;border-radius: 8px;object-fit: cover;object-position: right;">
        </div>
      </div>

    </div>

    <div style="display:none !important" class="d-flex gap-6 justify-between flex-column flex-md-row"
      :style="$vuetify.display.width < 752 ? { padding: '0 15px' } : {}">

      <div class="box_section_01" st>
        <div
          style="position: absolute;content: '';width: 100%;height:100%;background: linear-gradient(to bottom, #33adff, #0502e199);bottom: 0;left: 0;">
        </div>

        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="#99d6ff" />
            <use xlink:href="#gentle-wave" x="48" y="3" fill="#66c2ff" />
            <use xlink:href="#gentle-wave" x="48" y="5" fill="#33adff" />
            <use xlink:href="#gentle-wave" x="48" y="7" fill="#99d6ff" />
          </g>
        </svg>



        <div class="w-100 h-100" style="position: absolute;bottom: 0;left: 0;padding: 20px;">
          <div class="d-flex flex-column w-100 h-100 justify-space-between">
            <div class="d-flex justify-space-between">
              <p class="d-flex flex-column" style="line-height: 1.2;">
                <span style="color: #f2ff00;font-weight: 600;font-size: 20px;">{{ mes_actual }}</span>
                <!-- <span style="color: #fff;font-weight: 600;font-size: 25px;">{{ new Intl.NumberFormat('es-MX', {style:'currency', currency: 'MXN'}).format(venta_actual) }}</span> -->
                <h class="d-flex align-end">
                  <span style="color: #fff;font-weight: 600;font-size: 25px;">{{ venta_total.entero }}</span>
                  <span style="color: #d4eeff;font-weight: 600;font-size: 20px;">{{ venta_total.decimal }}</span>
                </h>

              </p>
              <v-btn icon="$vuetify" density="compact" variant="text"
                style="border: 2px solid #ffffff30;width:35px;height: 35px;color:rgba(255, 255, 255, 0.85);font-size: 26px;">
                <i class="fa-solid fa-ellipsis"></i>
              </v-btn>
            </div>

            <div class="d-flex w-100 justify-center">
              <ChartView :datos_ventas="datos_ventas" style="width: 100%" />
            </div>
          </div>
        </div>
      </div>

      <div class="box_section_02">
        <v-table fixed-header density="compact" style="width: 100%">
          <thead>
            <tr>
              <th style="font-weight: 600">
                Pedido
              </th>
              <!-- <th style="text-align: center;font-weight: 600">
                  Fecha Pedido
                </th> -->
              <th style="text-align: center;font-weight: 600">
                Paquetes
              </th>
              <!-- <th style="text-align: center;font-weight: 600">
                  Entregados
                </th>
                <th style="text-align: center;font-weight: 600">
                  Pendientes
                </th>
                <th style="text-align: center;font-weight: 600">
                  Paquetería
                </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in pedidos" :key="item.name">
              <td>{{ item.pedido }}</td>
              <!-- <td style="text-align: center">{{ item.fecha_pedido }}</td> -->
              <td style="text-align: center">{{ item.paquetes }}</td>
              <!-- <td style="text-align: center">{{ item.paquetes_entregados }}</td>
                <td style="text-align: center">{{ item.paquetes_pendientes }}</td>
                <td style="text-align: center">{{ item.paqueteria }}</td> -->
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>

    <div class="box_section_03 flex-column w-100" style="row-gap:25px;min-width: 50%;max-width: 1150px;overflow: hidden;"
      :style="$vuetify.display.width < 752 ? { borderRadius: '0', padding: '0 15px' } : $vuetify.display.width < 1281 ? {} : {}">
      <!-- <span style="font-size: 20px">Ranking</span> -->

      <div class="d-flex justify-space-between align-center">

        <div class="d-flex flex-column" style="font-family: Nunito !important;">
          <div class="d-flex align-center gap-1" style="line-height: 1;font-family: Nunito !important;">
            <span class="material-symbols-rounded" style="color:#ff005a">
              verified
            </span>
            <strong style="font-size: 24px;font-weight: 800;">
              DESTACADOS
            </strong>
          </div>
          <span style="color:#6184AA;font-size: 14px;font-weight: 500;">
            Revisa los descuentos de tus artículos destacados
          </span>
        </div>
      </div>

      <div class="d-flex justify-space-between gap-2" style="overflow: auto; padding-bottom: 10px;"
        v-if="tab_destacados == 'one'">
        <!-- <button type="button" class="btn_agregar_articulo" :class="$vuetify.display.width < 752 ? 'w-100':''" @click="add_articulo = true"><i class="fa-solid fa-plus mr-3"></i>Agregar Producto</button> -->
        <!-- <div class="d-flex flex-column" style="line-height: 25px;">
        <span style="font-size: 26px;font-weight: 500;color:#081c52">Nuevos Productos</span>
        <span style="color: #7995c0">Nuevos productos agregados recientemente</span>
       </div> -->


        <div :style="$vuetify.display.width < 752 ? { minWidth: '120px', width: '120px' } : { width: '24%' }"
          v-for="art in img_banner_03" :key="art">
          <img :src="art.img" style="width:100%;height: 100%;border-radius: 8px;">
        </div>

        <div class="d-flex gap-2 align-center" style="display:none !important">

          <div v-if="categoria_selected != ''">
            <v-chip style="color:#ff00b3" @click="categoria_selected = ''">
              <div>
                {{ categoria_selected }}
              </div>
              <i class="fa-solid fa-xmark ml-4"></i>
            </v-chip>
          </div>
          <div class="d-flex gap-2">
            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Categoría</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index" @click="seleccionar_categoria(item.title)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Precio</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Estatus</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

      </div>


      <div class="d-flex justify-space-between" v-if="tab_destacados == 'two'">
        <!-- <button type="button" class="btn_agregar_articulo" :class="$vuetify.display.width < 752 ? 'w-100':''" @click="add_articulo = true"><i class="fa-solid fa-plus mr-3"></i>Agregar Producto</button> -->
        <!-- <div class="d-flex flex-column" style="line-height: 25px;">
        <span style="font-size: 26px;font-weight: 500;color:#081c52">Nuevos Productos</span>
        <span style="color: #7995c0">Nuevos productos agregados recientemente</span>
       </div> -->


        <div style="width: 250px;">
          <img :src="img_banner_04" style="width:100%;height: 100%;border-radius: 18px;">
        </div>

        <div class="d-flex gap-2 align-center" style="display:none !important">

          <div v-if="categoria_selected != ''">
            <v-chip style="color:#ff00b3" @click="categoria_selected = ''">
              <div>
                {{ categoria_selected }}
              </div>
              <i class="fa-solid fa-xmark ml-4"></i>
            </v-chip>
          </div>
          <div class="d-flex gap-2">
            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Categoría</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index" @click="seleccionar_categoria(item.title)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Precio</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :location="location">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="plain"
                  style="color:#3d4549;box-shadow: none;border: 1px solid #dcdcdc;text-transform: capitalize;letter-spacing: normal;min-width: 130px;">
                  <div class="d-flex justify-space-between w-100 align-center"
                    style="position: absolute;padding: 0 10px;align-items: center;color: #3d4549;">
                    <span style="font-weight: 400;">Estatus</span>
                  </div>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>

      <div value="one">

        <div class="mb-7 d-flex justify-space-between align-center">
          <div class="d-flex flex-column" style="line-height: 1;">
            <div class="d-flex align-center gap-1" style="line-height: 1;font-family: Nunito !important;">
              <!-- <strong style="font-size: 24px;font-weight: 800;color:#ff005a">
                25
              </strong> -->
              <span class="material-icons" style="color: #ff005a">
                auto_awesome
              </span>
              <strong style="font-size: 19px;font-weight: 800;">
                AGREGADOS RECIENTEMENTE
              </strong>
            </div>
            <!-- <span style="color:#6184aa;font-size: 16px">
              Revisa los descuentos de tus artículos destacados
            </span> -->
          </div>

          <!-- <v-tabs v-model="tab_destacados" density="compact" hide-slider="false" mandatory="one">
            <v-tab :class="{ 'active-tab': tab_destacados === 'one' }" value="one">Vestidos</v-tab>
            <v-tab :class="{ 'active-tab': tab_destacados === 'two' }" value="two">Faldas</v-tab>
            <v-tab :class="{ 'active-tab': tab_destacados === 'three' }" value="three">Blusas</v-tab>
          </v-tabs> -->

        </div>


        <div ref="stickySection" class="d-flex flex-wrap" style="overflow: hidden;margin: 0 -6px;">
          <template v-for="producto of productos">

            <div @click="preview_producto(producto)"
              v-if="JSON.parse(producto.sucursales_producto).includes('HETE202') || state.sessionActive == 1 || state.sessionActive == 2"
              style="padding: 3px 7px;" :key="producto"
              :style="$vuetify.display.width > 1009 ? { width: '20%' } : $vuetify.display.width > 400 ? { width: '25%' } : { width: '50%' }">

              <carousel wrapAround style="border-radius: 15px;overflow:hidden"
                :mouseDrag="JSON.parse(producto.imagenes_productos).length > 1 ? true : false"
                :touchDrag="JSON.parse(producto.imagenes_productos).length > 1 ? true : false">

                <slide v-for="imagen of JSON.parse(producto.imagenes_productos)" :key="imagen"
                  style="position: relative;">



                      <div v-bind="props" class="box_img">

                        <span v-if="producto.status_producto == 'Agotado' || producto.status_producto == 'Próximamente'" style="position: absolute;top:0;left:0;width: 100%;height: 100%;display: grid;place-content: center; background:#00000065;color: #fff;font-weight: 600;overflow: hidden;">
                          {{ producto.status_producto }}
                        </span>

                        <img loading="eager" style="width:100%;height: 100%;object-fit: cover;" :src="imagen">

                        <span class="d-flex align-center justify-center" style="padding: 2px 10px;border-radius: 5px;outline: 1px solid #000000ef;color:#fff;background: #00000080;position: absolute;bottom: 15px;right: 10px;min-width: 30px;min-height: 30px;">
                          {{ producto.stock_producto }}
                        </span>

                      </div>
                </slide>
              </carousel>

              <div class="py-1">
                <div class="d-flex flex-column">

                  <p class="d-flex flex-column justify-center">
                    <h style="font-weight: 500;text-overflow: ellipsis;white-space: nowrap;width: 95%;overflow: hidden;"
                      :style="$vuetify.display.width < 752 ? { fontSize: '11px' } : { fontSize: 'small' }">
                      {{ producto.nombre_producto }}
                    </h>
                  </p>
                  <div class="d-flex justify-space-between pb-1 gap-1">
                    <p class="d-flex flex-column justify-space-around ma-0" style="line-height:1.2">
                      <span style="font-size:11px;color:gray; font-weight: 500;">{{ producto.marca_producto }}</span>
                      <span style="width:10px;height: 10px;border-radius: 50px;"
                        :style="{ background: producto.color_producto }"></span>
                    </p>
                    <p class="d-flex flex-column ma-0" style="line-height:1.2;height:31px">
                      <span style="font-weight: 600;color:red;font-size:14px" v-if="producto.descuento_producto">
                        {{ numberFormat.format(producto.precio_producto * (1 - (producto.descuento_producto / 100))) }}
                      </span>
                      <span
                        :style="producto.descuento_producto ? { textDecoration: 'line-through', color: 'gray', fontWeight: '400', fontSize: '12px' } : { fontWeight: '600', color: 'red', fontSize: '14px' }">
                        {{ numberFormat.format(producto.precio_producto) }}
                      </span>
                    </p>
                  </div>

                </div>
                <!-- <div class="d-flex gap-2">
                    <v-btn icon="$vuetify" density="compact" variant="text" class="bottom_icon" @click="mostrar_datos">
                      <i class="fa-regular fa-heart"></i>
                    </v-btn>
                    <v-btn density="compact" variant="text" class="btn_agregar">Agregar</v-btn>
                  </div> -->
              </div>
            </div>

          </template>
          <template v-for="producto_skeleton in 6" :key="producto_skeleton">
            <div v-if="state.loading" style="padding: 0px 7px;height: 100% !important;"
              :style="$vuetify.display.width > 1009 ? { width: '20%' } : $vuetify.display.width > 400 ? { width: '25%' } : { width: '50%' }">
              <div class="d-flex flex-column" id="box_productos">

                <v-skeleton-loader :loading="loading" theme="article" type="image" height="240"></v-skeleton-loader>
                <v-skeleton-loader :loading="loading" type="list-item-two-line"
                  style="background: #F5F9FF;border-radius: 0;"></v-skeleton-loader>

              </div>
            </div>
          </template>


        </div>



        <!-- <v-alert variant="outlined" type="warning" prominent v-if="productos <= 0" class="w-100">
          No se encontraron productos que coincidan con la busqueda
        </v-alert> -->


      </div>
    </div>

  </div>


  <v-dialog v-model="add_articulo" fullscreen :scrim="false" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark style="background: #000" class="d-flex justify-space-between gap-2">

        <v-btn icon variant="plain" style="color: #fff" @click="add_articulo = false">
          <i class="fa-solid fa-angle-left" style="color:#fff"></i>
        </v-btn>

        <v-toolbar-title style="color: #fff;padding-right: 20px;"
          :style="$vuetify.display.width < 752 ? { textAlign: 'right' } : { textAlign: 'center' }">Nuevo
          Producto</v-toolbar-title>

      </v-toolbar>
      <v-list lines="two" subheader :class="$vuetify.display.width > 752 ? 'd-flex justify-center h-100' : ' pa-0'">
        <AddArticulo :style="$vuetify.display.width > 752 ? { maxWidth: '1220px' } : {}" />
      </v-list>
    </v-card>
  </v-dialog>

  <v-dialog v-model="actualizar_articulo" fullscreen>
    <v-card>
      <v-toolbar dark style="background: #000" class="d-flex justify-space-between gap-2">

        <v-btn icon variant="plain" style="color: #fff" @click="actualizar_articulo = false">
          <i class="fa-solid fa-angle-left" style="color:#fff"></i>
        </v-btn>

        <v-toolbar-title style="color: #fff;padding-right: 20px;"
          :style="$vuetify.display.width < 752 ? { textAlign: 'right' } : { textAlign: 'center' }">{{
            data_edit_producto.nombre_producto }}</v-toolbar-title>

      </v-toolbar>
      <!-- style="background: #121921;color: #cde0f4 !important" -->
      <v-list lines="two" subheader :class="$vuetify.display.width > 752 ? 'd-flex justify-center h-100' : ' pa-0'">
        <EditProducto :producto="data_edit_producto" :style="$vuetify.display.width > 752 ? { maxWidth: '1220px' } : {}"
          @cerrar_edit_producto="actualizar_articulo = false; preview = false" />
      </v-list>
    </v-card>
  </v-dialog>

  <v-dialog v-model="preview" max-width="500px">
    <div style="position: absolute; top: 0; left: 0;width:100%" class="d-flex justify-space-between pa-3">
      <v-btn icon size="small" variant="flat" style="background: #00000050;" @click="preview = false">
        <i class="fa-solid fa-angle-left" style="color: #fff;"></i>
      </v-btn>



      <!-- <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon size="small" variant="flat" v-bind="props" style="background: #00000050;">
            <i class="fa-solid fa-ellipsis" style="color: #fff;"></i>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in [{title: 'Editar', metodo: () => editProducto(producto_preview)}]"
            :key="index"
            :value="index"
          >
            <v-list-item-title @click="item.metodo()">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- <v-list-item
            v-for="(item, index) in [{title: 'Editar', metodo: () => editProducto(producto_preview)}]"
            :key="index"
            :value="index"
          >
            <v-list-item-title @click="item.metodo()">{{ item.title }}</v-list-item-title>
          </v-list-item> -->



      <v-menu location="start" v-if="state.sessionActive == 1 || state.sessionActive == 2">
        <template v-slot:activator="{ props }">
          <v-btn icon size="small" variant="flat" v-bind="props" style="background: #00000050;">
            <i class="fa-solid fa-ellipsis" style="color: #fff;"></i>
          </v-btn>
        </template>
        <div style="background: #000000d9;backdrop-filter: blur(3px);z-index: 5;" class="pa-2">

          <v-btn v-for="(item, index) in [{ title: 'Editar', metodo: () => editProducto(producto_preview) }]" :key="index" :value="index" variant="text" style="color:#fff;" @click="item.metodo()">{{ item.title }}</v-btn>

        </div>
      </v-menu>
    </div>

    <img :src="JSON.parse(producto_preview.imagenes_productos)" style="width: auto; max-height: calc(100vh - 20px);">
  </v-dialog>
</template>


<script>
import ChartView from '../components/ChartComponent.vue'
import AddArticulo from '../components/AddArticulo.vue'
import EditProducto from '../components/EditProducto.vue'

import video from "@/assets/img/video_backgrond.mp4";
import { state } from '../funciones_globales'

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

import axios from 'axios';

export default ({

  components: {
    ChartView,
    Carousel,
    Slide,
    AddArticulo,
    EditProducto
  },
  data: () => ({
    categoria_selected: '',

    imagenes_banner: [
      { img: require('../assets/img/banner_principal_01.jpg') },
      { img: require('../assets/img/banner_principal_02.jpg') },
      { img: require('../assets/img/banner_principal_03.jpg') }
    ],

    add_articulo: false,
    actualizar_articulo: false,
    preview: false,
    producto_preview: {},
    data_edit_producto: null,

    numberFormat: new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      maximumFractionDigits: 0
    }),
    img_banner_01: require('../assets/img/banner_02.webp'),
    img_banner_02: require('../assets/img/banner_03.jpg'),
    img_banner_03: [
      { img: require('../assets/img/destacados_01.jpg') },
      { img: require('../assets/img/promocion_destacado_01.jpg') },
      { img: require('../assets/img/destacados_02.jpg') },
      { img: require('../assets/img/promocion_destacado_02.jpg') },
      { img: require('../assets/img/destacados_03.jpg') },
      { img: require('../assets/img/promocion_destacado_03.jpg') }
    ],
    img_banner_04: require('../assets/img/banner_04.jpg'),
    videoSource: video,
    // chartConfig
    nombresMeses: [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    mes_actual: null,
    datos_ventas: [
      { mes: 'Ene', venta: 50 },
      { mes: 'Feb', venta: 55 },
      { mes: 'Mar', venta: 40 },
      { mes: 'Abr', venta: 45 },
      { mes: 'May', venta: 30 },
      { mes: 'Jun', venta: 25 },
      { mes: 'Jul', venta: 20 },
      { mes: 'Ago', venta: 50 },
      { mes: 'Sep', venta: 35 },
      { mes: 'Oct', venta: 45 },
      { mes: 'Nov', venta: 30 },
      { mes: 'Dic', venta: 55 }
    ],
    venta_actual: 7856.87,
    items: [
      {
        title: 'Paquetes',
        value: 1,
        props: {
          appendIcon: 'mdi-chevron-right',
          color: '#33adff',
        },
      },
      {
        title: 'Pedidos',
        value: 2,
        props: {
          appendIcon: 'mdi-chevron-right',
          color: '#33adff',
        },
      },
      {
        title: 'Apartados',
        value: 3,
        props: {
          appendIcon: 'mdi-chevron-right',
          color: '#33adff',
        },
      },
    ],
    pedidos: [
      {
        pedido: 'GBDC104520045801',
        fecha_pedido: '11/02/2024',
        paquetes: 19,
        paquetes_entregados: 5,
        paquetes_pendientes: 14,
        paqueteria: 'Estafeta'
      },
      {
        pedido: 'GBDC125520045887',
        fecha_pedido: '09/02/2024',
        paquetes: 11,
        paquetes_entregados: 3,
        paquetes_pendientes: 8,
        paqueteria: 'J&T'
      },
      {
        pedido: 'GBDC104527445836',
        fecha_pedido: '07/02/2024',
        paquetes: 5,
        paquetes_entregados: 2,
        paquetes_pendientes: 3,
        paqueteria: 'Imile'
      },
      {
        pedido: 'GBDC104583558045',
        fecha_pedido: '07/02/2024',
        paquetes: 13,
        paquetes_entregados: 9,
        paquetes_pendientes: 4,
        paqueteria: 'Estafeta'
      },
      {
        pedido: 'GBDC1685420045887',
        fecha_pedido: '03/02/2024',
        paquetes: 10,
        paquetes_entregados: 10,
        paquetes_pendientes: 0,
        paqueteria: 'Imile'
      },
      {
        pedido: 'GBDG584520049801',
        fecha_pedido: '03/02/2024',
        paquetes: 27,
        paquetes_entregados: 27,
        paquetes_pendientes: 0,
        paqueteria: 'AMPM'
      }
    ],
    tab_destacados: 'one',

    stickyOffset: 0
  }),
  setup() {
    return {
      state
    }
  },
  computed: {
    productos() {
      // return state.buscador_productos != '' ? state.images_populares_filter:state.images_populares
      return state.productos
    },
    venta_total() {

      const formattedValue = this.numberFormat.format(this.venta_actual);
      const decimalPart = this.venta_actual % 1 === 0 ? '' : (this.venta_actual % 1).toFixed(2).substring(1);

      return {
        entero: formattedValue,
        decimal: decimalPart
      }
    }
  },


  created() {
    this.mostrar_productos();
    this.mes_actual = this.nombresMeses[new Date().getMonth()];
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    mostrar_productos() {
      this.cargar_productos(state.limit);
    },
    cargar_productos(limit) {
      console.log(window.innerHeight, window.scrollY, document.body.offsetHeight, state.loading);
      if (state.loading) return;

      state.loading = true;

      axios.get(`https://nexus-ux.com/microservicio/public/api/producto/consultarProductos?page=${state.page}&limit=${limit}`)
        .then(res => {
          if (res.data && Array.isArray(res.data)) {
            state.productos = [...state.productos, ...res.data];
            state.page++;
          } else {
            console.error('Datos no válidos recibidos de la API');
          }
          state.loading = false;
        })
        .catch(error => {
          console.error('Error al cargar imágenes:', error);
          state.loading = false;
        });
    },
    handleScroll() {
      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 700) && !state.loading) {
        this.cargar_productos(state.limit);
      }
    },
    preview_producto(producto) {
      this.preview = true
      this.producto_preview = producto
    },
    editProducto(producto) {
      this.actualizar_articulo = true
      this.data_edit_producto = producto
    },
    seleccionar_categoria(categoria) {
      this.categoria_selected = categoria
    },
  }

});
</script>

<style scoped>
.contenedor {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.box_img_banner {
  min-height: 200px;
  max-height: 350px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 25px rgba(100, 150, 200, 0.2);
  overflow: hidden;
}

.box_section_01 {
  display: flex;
  height: 210px;
  flex-grow: 1;
  max-width: 370px;
  background: linear-gradient(to right, #33adff, #0502e1);
  border-radius: 15px;
  box-shadow: 0px 5px 25px rgba(100, 150, 200, 0.2);
  position: relative;
  overflow: hidden
}

.box_section_02 {
  display: flex;
  flex-grow: 1;
  height: 210px;
  padding: 10px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 25px rgba(100, 150, 200, 0.2);
}

.box_section_03 {
  display: flex;
  /* background: #fff; */
  border-radius: 15px;
  /* box-shadow: 0px 5px 25px rgba(100, 150, 200, 0.2); */
}

.waves {
  opacity: .15;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;

  min-height: 100px;
  max-height: 150px;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -5s;
  animation-duration: 10s;
}

.parallax>use:nth-child(2) {
  animation-delay: -10s;
  animation-duration: 25s;
}

.parallax>use:nth-child(3) {
  animation-delay: -15s;
  animation-duration: 15s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 30s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}

.image-container {
  position: relative;
}

.box_img {
  width: 100%;
  position: relative;
  /* height: 300px; */
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 100% !important;
}

.contenido_articulo {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s, visibility 0.6s;
}

.box_img:hover .contenido_articulo {
  visibility: visible;
  opacity: 1;
  background: #00000050;
  top: 0;
}

.btn_opciones {
  border-radius: 15px !important;
  border: 2px solid #00000020;
  color: #000000 !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(188.5px, 216px));
}

#box_tabs_inicio_articulos .v-slide-group__content {
  column-gap: 10px;
}

.bottom_icon {
  background: #ffffffd3;
  width: 35px;
  height: 35px;
  font-size: 16px;
  /* border: 2px solid #00000030; */
}

.btn_agregar {
  background: #000;
  color: #fff !important;
  text-transform: none;
  letter-spacing: normal;
  border-radius: 0;
  flex-grow: 1;
}

.btn_agregar_articulo {
  background-color: #000000;
  color: #fff !important;
  padding: 8px 80px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.btn_agregar_articulo:hover {
  background-color: #131313;
}

.active-tab {
  background: #000;
  color: #fff !important;
}

 #custom-alert {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

#custom-alert-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

#custom-alert-content button {
    margin-top: 10px;
}
</style>