<template>
  <div class="d-flex h-100 w-100">
    
    <!-- <BottomBar style="position:fixed;z-index: 99999"/> -->
    <!-- <SideBar style="position:fixed;"/> -->
    <!-- <div class="w-100" :style="!($vuetify.display.width < 1009) ? {paddingLeft:'300px'}:{}"> -->
    <div class="w-100">
        <NavBar style="position:fixed;z-index: 9999;"/>
        <router-view style="padding-top:80px"/>
        <FooterBar/>
    </div>

  </div>
</template>

<script>
// import SideBar from './components/SideBar.vue';
import NavBar from './components/NavBar.vue';
// import BottomBar from './components/BottomBar.vue'
import FooterBar from './components/FooterBar.vue'

export default {
  name: 'App',

  components: {
    // SideBar,
    NavBar,
    // BottomBar,
    FooterBar
  },

  data: () => ({
    //
  }),
  // created(){
  //   localStorage.clear()
  // },
  methods: {
  }
}
</script>
