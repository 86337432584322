<template>
  <div class="chart-container">
    <template v-for="(data, index) in datos_ventas" :key="index">
      <div class="d-flex flex-column align-center">
        <div class="bar" :style="{ height: data.venta + 'px' }"></div>
        <span class="label">{{ data.mes }}</span>
      </div>
    </template>
  </div>
</template>

<style>
  .chart-container {
    max-width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: auto;
  }

  .bar {
    width: 10px;
    background-color: #8fd2ff;
    border-radius: 15px;
    transition: height 0.5s ease;
    overflow: hidden;
  }

  .bar:hover {
    height: 120px; /* Altura máxima durante el hover */
  }

  .label {
    text-align: center;
    margin-top: 10px;
    font-size: 9px;
    color: #fff;
    font-weight: 600;
  }
</style>

<script>
export default {
  props: {
    datos_ventas: null
  },
  data: () => ({

  }),
  created(){
    // console.log('labels:', this.labels);
  }
};
</script>
