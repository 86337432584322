<template>
  <div class="contenedor" :style="$vuetify.display.width > 752 ? {padding: '50px 80px', justifyContent: 'center'}:{padding: '15px', paddingBottom: '80px'}">

    <div>
      <form id="uploadForm" class="upload-form" enctype="multipart/form-data"
        :style="$vuetify.display.xs ? { fontSize: 'small' } : {}">
        <!-- <h2>Subir Artículo</h2> -->

        <div class="d-flex gap-4 flex-wrap select_producto" :class="$vuetify.display.width < 600 ? 'flex-column' : ''">
          <div class="d-flex gap-3" :class="$vuetify.display.width < 600 ? 'w-100' : ''">

            <div style=" max-width: 91px;overflow: hidden;">
              <div style="overflow-y: auto;overflow: hidden auto;display: grid;gap: 15px;width: 140px;" :style="$vuetify.display.width > 752 ? {height: '400px'}:{height: '300px'}">
                <template v-for="image of imagenes.length > 0 ? imagenes : 6" :key="image">
                  <div style="width: 90px;height: 110px;background: #F1F5FF;border-radius: 8px" @click="imagenes.length > 0 ? intercambiarImagen(image):null">
                    <img :src="image" class="w-100 h-100" style="object-fit: cover;border-radius:8px;" v-if="image && imagenes.length > 0">
                  </div>
                </template>
              </div>
            </div>

            <div :style="$vuetify.display.width < 600 ? { width: 'inherit', height: '300px' } : { minWidth: '300px', maxWidth: '300px', height: '400px'}" class="flex-grow-1 d-flex justify-center align-center box_add_image" @click="agregarImagenes">
              <div class="d-flex flex-column align-center gap-3" style="font-size: 14px; color: #6c84c1;" v-if="imagenExtraida == null">
                <i class="fa-solid fa-file-circle-plus" style="color: #a5b9ea;font-size:30px;"></i>
                <span>Add Imagen</span>
              </div>
              <img :src="imagenExtraida" class="w-100 h-100" style="object-fit: cover;" v-if="imagenExtraida" alt="Imagen extraída">
            </div>

          </div>
          <div class="flex-grow-1" style="width: min-content;" :class="$vuetify.display.width < 600 ? 'w-100' : ''">

            <div class="d-flex gap-3 flex-wrap pb-4">
              <v-text-field label="Nombre" density="compact" v-model="nombre_producto" required variant="outlined" class="flex-grow-1 input_nombre" :style="$vuetify.display.width < 600 ? { width: '30%' } : {}"></v-text-field>
              <v-text-field label="Precio" density="compact" v-model="precio_producto" required variant="outlined" class="flex-grow-1" style="max-width:150px" :style="$vuetify.display.width < 600 ? { width: '0' } : {}"></v-text-field>
            </div>

            <v-textarea label="Descripción" density="compact" class="pb-4" v-model="descripcion_producto" required variant="outlined"></v-textarea>

            <div class="d-flex gap-3 flex-wrap pb-4">
                <v-select density="compact" style="width: 150px;" label="Categoría" v-model="categoria_producto" required :items="['Mujer', 'Hombre', 'Infantil']" variant="outlined"></v-select>
                <v-select density="compact" style="width: 145px;" label="Sub Categoría" v-model="subcategoria_producto" required :items="['Ropa', 'Maquillaje', 'Pestañas', 'Accesorios']" variant="outlined"></v-select>
                <v-select density="compact" style="width: 150px;" :class="$vuetify.display.width < 600 ? 'w-100' : ''" label="Marca" v-model="marca_producto" required :items="['Shein', 'H&M', 'Liverpool']" variant="outlined"></v-select>
                <v-select density="compact" style="width: 150px;" class="flex-grow-1" :style="$vuetify.display.width < 600 ? { width: '47%' } : {}" label="Color" v-model="color_producto" required :items="colores" variant="outlined">
                  <template #prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Color seleccionado</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon :style="{ color: color_producto }">mdi-circle</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-select>
              
                <v-select density="compact" class="flex-grow-1" :style="$vuetify.display.width < 600 ? { width: '48%' } : {minWidth: '150px'}" label="Medida" v-model="medida_producto" required :items="['XS', 'S', 'M', 'L', 'XL']" variant="outlined"></v-select>

            </div>

            <div class="d-flex flex-wrap gap-3 pb-4">
                <v-select density="compact" style="width: 100px;" label="Status" v-model="status_producto" required :items="['Disponible', 'Agotado', 'Próximamente']" variant="outlined"></v-select>
                <v-text-field label="Descuento" density="compact" v-model="descuento_producto" required variant="outlined" class="flex-grow-1"></v-text-field>
                <v-text-field label="Stock" density="compact" v-model="stock_producto" required variant="outlined" class="flex-grow-1"></v-text-field>
            </div>
            <div class="w-100 d-flex justify-end">
              <button type="button" class="btn_subir_articulo" :class="$vuetify.display.width < 600 ? 'w-100':''" @click="guardar_articulo()">Subir Producto</button>
            </div>

          </div>

        </div>

        <v-file-input v-model="image_upload" @change="imagen_preview($event)" ref="fileInput"
          style="display: none;visibility: hidden;opacity: 0;" label="Cargar imágenes (máximo 4)" multiple :max="4"
          accept="image/*"></v-file-input>

      </form>
    </div>
  </div>
</template>


<script>
// import { fecha_hora_actual } from '../funciones_globales'
import axios from 'axios';


export default ({

  data: () => ({
    image_upload: [],
    url_image_upload: [],
    imagenes: [],
    url_site: 'https://nexus-ux.com/microservicio/public/',

    imagenExtraida: null,
    nombre_producto: '',
    descripcion_producto: '',
    precio_producto: null,
    categoria_producto: 'Mujer',
    subcategoria_producto: 'Ropa',
    marca_producto: 'Shein',
    medida_producto: null,
    color_producto: null,
    status_producto: 'Disponible',
    descuento_producto: 0,
    stock_producto: '',

    colores: [
      'Black',
      'Orange',
      'Gray',
      'Red',
      'Beige',
      'Brown',
      'DarkBlue',
      'Green',
      'Purple',
      'LightPink'
    ]

  }),
  created() {
  },
  mounted() {
    // Observador para detectar cambios en el input de archivo
    this.$refs.fileInput.addEventListener('change', this.imagen_preview);
  },
  methods: {

    agregarImagenes() {
      this.image_upload = [];
      this.$refs.fileInput.click();
    },
    imagen_preview(event) {
      const input = event.target;
      const files = input.files;
      const cantidad = files.length;
      let valida = true;

      // Verificar si la cantidad de imágenes es mayor que 4
      if (cantidad > 7) {
        alert('Solo se pueden cargar un máximo de 4 imágenes');
        input.value = ''; // Limpiar el input de archivo
        return;
      }

      for (let i = 0; i < cantidad; i++) {
        const nombre = files[i].name;
        const ext = nombre.substring(nombre.lastIndexOf("."));
        if (ext !== ".png" && ext !== ".jpg") {
          valida = false;
          break;
        }
      }

      if (valida) {
        // Limpiamos el array imagenes antes de agregar nuevas imágenes
        this.imagenes = [];

        // Asignamos la primera imagen a una variable separada
        if (cantidad > 0) {
          const imagenExtraida = files[0];
          this.previsualizarImg(imagenExtraida, 'imagenExtraida');
        }

        // Agregamos el resto de las imágenes al array imagenes
        for (let i = 1; i < cantidad; i++) {
          const imagen = files[i];
          this.previsualizarImg(imagen, 'imagenes');
        }
      } else {
        alert('Por favor selecciona solo archivos con extensión .png o .jpg');
        input.value = ''; // Limpiamos el input de archivo
      }
    },
    previsualizarImg(file, tipo) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl = reader.result;
        // Agregamos la imagen a la variable especificada
        if (tipo === 'imagenExtraida') {
          this.imagenExtraida = imageUrl;
        } else if (tipo === 'imagenes' && !this.imagenes.includes(imageUrl)) {
          this.imagenes.push(imageUrl);
        }
        
        this.url_image_upload.push(imageUrl);
      };
      reader.readAsDataURL(file);
    },
    intercambiarImagen(image) {
  // Guardamos la imagen extraída antes de cambiarla
  const temp = this.imagenExtraida;
  // Cambiamos la imagen extraída por la imagen del parámetro
  this.imagenExtraida = image;
  // Obtenemos el índice de la imagen clicada en el array imagenes
  const index = this.imagenes.indexOf(image);
  if (index !== -1) {
    // Eliminamos la imagen clicada del array imagenes y la insertamos en su lugar la imagen previamente extraída
    this.imagenes.splice(index, 1, temp);
  }
  
  console.log('imagenes:', this.url_image_upload)
},

    guardar_articulo() {
      console.log(this.nombre_producto, this.descripcion_producto, this.image_upload)

      // let data_producto = {
      //   nombre_producto: this.nombre_producto
      // }

      // *******************************************   FUNCION PARA GUARDAR IMAGENES   *******************************************
      let data_img = null;
      // if (this.image_upload != null) {
      //   let nombreImg = this.image_upload[0].name;
      //   if (nombreImg.includes(".jpg") || nombreImg.includes(".png")) {
      //     if (this.image_upload[0].size > 2000000) {
      //       this.$swal("¡Oops, la imagen supera los 2 MB!", "Sube una más ligera", "info");
      //     }

      //     data_img.append("img", this.image_upload[0]);

      //   } else {
      //     this.$swal("¡Oops, el archivo no es una imagen!", "Sube una imagen", "info");
      //   }
      // }

      // if (data_img) {

        let datos_imagenes = []
        let contador = 0
        this.image_upload.forEach(element => { 

          data_img = new FormData();
          data_img.append("img", element);

          axios.post('https://nexus-ux.com/microservicio/public/api/producto/subirImg', data_img).then(res => {
            // widget.value = res.data.path.replace(/\\/g, '/');
            console.log('respuesta: ', res.data.path);
            console.log('contador: ', contador, 'image_upload: ', this.image_upload.length);


            datos_imagenes.push(this.url_site + res.data.path)

            if((contador + 1) == this.image_upload.length){
              this.agregar_datos_producto(datos_imagenes)
            }else{
              contador = contador + 1
            }

          }).catch(error => {
            console.error("Error uploading the image:", error);
          });
        })
      // }
    },
    agregar_datos_producto(datos_imagenes) {

      console.log('datos_imagenes: ', datos_imagenes);
          
          const ahora = new Date();

          // Obtenemos los componentes de la fecha y hora
          const dia = ahora.getDate().toString().padStart(2, '0');
          const mes = (ahora.getMonth() + 1).toString().padStart(2, '0'); // Los meses comienzan desde 0
          const anio = ahora.getFullYear();
          const horas = ahora.getHours().toString().padStart(2, '0');
          const minutos = ahora.getMinutes().toString().padStart(2, '0');
          const segundos = ahora.getSeconds().toString().padStart(2, '0');

          // Creamos el formato deseado
          const fecha_hora_actual = `${dia}${mes}${anio}-${horas}${minutos}${segundos}`;

          let data_producto = {
            imagenes_productos: JSON.stringify(datos_imagenes), // Rutas de las imágenes separadas por coma
            nombre_producto: this.nombre_producto,
            detalles_producto: this.descripcion_producto,
            precio_producto: this.precio_producto,
            descuento_producto: this.descuento_producto, // Porcentaje de descuento
            key_producto: this.nombre_producto.substring(0, 3) + fecha_hora_actual,
            categoria_producto: this.categoria_producto,
            subcategoria_producto: this.subcategoria_producto,
            medida_producto: this.medida_producto,
            marca_producto: this.marca_producto,
            color_producto: this.color_producto,
            stock_producto: this.stock_producto,
            ranking_producto: 0
          };

          // http://localhost:8085/api/sitio/
          // axios.post('https://nexus-ux.com/microservicio/public/api/img/insertarProducto', data_producto).then(res => {
          axios.post('https://nexus-ux.com/microservicio/public/api/producto/insertarProducto', data_producto).then( () => {
            // widget.value = res.data.path.replace(/\\/g, '/');
            
            this.imagenes = []
            this.datos_imagenes = []
            
            this.imagenExtraida = null
            this.nombre_producto = ''
            this.descripcion_producto = ''
            this.precio_producto = null
            this.categoria_producto = 'Mujer'
            this.subcategoria_producto = 'Ropa'
            this.marca_producto = 'Shein'
            this.medida_producto = null
            this.color_producto = null
            this.status_producto = 'Disponible'
            this.descuento_producto = 0
            this.stock_producto = ''
            
            
          }).catch(error => {
            console.error("Error uploading the image:", error);
          });

        },

    // imagen_preview(event) {

    //   const file = event.target.files[0];
    //   const reader = new FileReader();

    //   reader.onload = (e) => {
    //     this.imagenExtraida = e.target.result;
    //   };
    //   reader.readAsDataURL(file)
    //   console.log('file: ', this.imagenExtraida);
    // }
  }

});
</script>


<style scoped>
.box_add_image {
  background: #F1F5FF;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.box_add_image:hover::after {
  content: '';
  position: absolute;
  width: 90%;
  height: 93%;
  border: 1px dashed #a5b9ea;
  border-radius: 8px;
}

.container {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  color: #667588;
}

.upload-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  width: 80px;
  flex-grow: 1 !important;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input_add_articulo {
  width: 100%;
  padding: 10px;
  border: 1px solid #D1DEF4;
  border-radius: 5px;
}

textarea {
  resize: vertical;
}

.btn_subir_articulo {
  background-color: #000000;
  color: #fff !important;
  padding: 8px 75px;
  border: none;
  /* border-radius: 50px; */
  cursor: pointer;
  font-size: 16px;
}

.btn_subir_articulo:hover {
  background-color: #000000f8;
}
</style>