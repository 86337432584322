<template>
 
 <div style="background: #000; color: #fff;" class="d-flex justify-center pa-4">
    <div style="width: 1150px;" class="d-flex flex-column gap-2">
      <div class="d-flex">
        <i class="fa-solid fa-location-dot mr-2 pt-1" style="color: #ff005a"></i>
        <span>Tabiqueros de Echeveste #202, Hacienda Echeveste</span>
      </div>
      <p><i class="fa-brands fa-whatsapp mr-2" style="color:lime"></i> 477 268 9595</p>
    </div>
  </div>

</template>

<script>
import { state } from '@/funciones_globales';

export default {
  data: () => ({
  }),
  setup() {
    return {
      state
    }
  },
}
</script>


<style>

</style>
