<template>
  <v-menu rounded transition="slide-y-transition">
    <template v-slot:activator="{ props }">

      <!-- <v-btn icon="$vuetify" density="compact" variant="text" style="width:35px;height: 35px;" @click="recuperarLogin()">
            <i class="fa-solid fa-bell" style="color:#0084ff"></i>
          </v-btn> -->


      <v-btn class="ml-3 d-flex align-center" v-bind="props" icon="$vuetify" density="compact" variant="text" style="width:35px;height: 35px;">
        <!-- <v-badge :dot="unreadNotifications.length <= 0 ? true:false" color="error" :content="unreadNotifications.length" floating> -->
          <span class="material-icons" style="font-size:30px">notifications</span>
          <!-- <span class="material-icons" style="font-size:30px">
            notifications_none
          </span> -->
        <!-- </v-badge> -->
      </v-btn>
    </template>
    <!-- <v-card class="mt-3 ml-n14" style="background: #000000d9;color:#fff;backdrop-filter: blur(3px);z-index: 5;">

    <v-col class="notifications">
      <p class="pb-2 mb-3" style="border-bottom: 1px solid #ffffff20;"><b>Notificaciones</b></p>
      <v-btn variant="text" v-for="(notification, index) in notifications" :key="index" @click="markAsRead(index)" style="width:100%;height: 75px;" class="pa-3 d-flex justify-start align-start">
        <p :style="{'color': notification.clicked ? '#ffffff99' : '#ffffff'}">{{ notification.text }}</p>
    </v-btn>
  </v-col>
    </v-card> -->
  </v-menu>
</template>

<script>
// import { state } from '../funciones-globales'

export default {

  data: () => ({
    user: {
      initials: 'JD',
      fullName: 'John Doe',
      email: 'john.doe@doe.com',
    },
    
    showNotification: false,
      notifications: [
        { text: 'Notification 1', read: false },
        { text: 'Notification 2', read: false },
        { text: 'Notification 3', read: false }
      ]
  }),
  computed: {
    // dark_mode() {
    //   return state.dark_mode_value
    // },
    unreadNotifications() {
      return this.notifications.filter(notification => !notification.read);
    },
  },
  methods:{
    
    markAsRead(index) {
      this.notifications[index].read = true
    this.notifications[index].clicked = true;
    }
  },
}
</script>


<style scoped>
.notifications {
  padding: 20px;
  width: 300px;
}
</style>